/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  allCurrency: [],
  savedCurrency: []
};

export const currencySlice = createSlice({
  name: Slices.currency,
  initialState,
  reducers: {
    setAllCurrency: (state, action) => {
      state.allCurrency = action.payload;
    },

    setSavedCurrency: (state, action) => {
      state.savedCurrency = action.payload;
    }
  }
});

export const { setAllCurrency, setSavedCurrency } = currencySlice.actions;

export default currencySlice.reducer;
