import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Tab, Tabs } from '@mui/material';
import { NavigatorForm } from 'components/form/navigatorForm';
import { NavigatorCreateForm } from 'components/form/navigatorCreateForm';
import { Questions } from 'components/settings/questions';
import { ChatsAndChannels } from 'components/settings/chatsAndChannels';
import s from './index.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className={s.tabpanel__container}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const SettingsNavBot = () => {
  const [value, setValue] = React.useState(0);
  const isNavigatorExists = useSelector((state) => state.navigator.isNavigatorExists);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={s.container}>
      <h1 className={s.page__title}>Навигационный бот</h1>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="настройки Бота" {...a11yProps(0)} />
            <Tab label="Чаты и каналы" {...a11yProps(1)} disabled={!isNavigatorExists} />
            <Tab label="Вопросы" {...a11yProps(2)} disabled={!isNavigatorExists} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} style={{ padding: 0 }}>
          {isNavigatorExists ? <NavigatorForm /> : <NavigatorCreateForm />}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ChatsAndChannels />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Questions />
        </TabPanel>
      </Box>
    </div>
  );
};
