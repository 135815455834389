import React from 'react';
import { StatisticsSVG, UserSVG, ListSVG, BoxSVG, SettingsSVG } from 'resources/svg';

export const apiUrl = process.env.REACT_APP_API_URL; // ENV

console.log(apiUrl);
// export const apiUrl = 'https://api.chatbot-dev.asap-lp.ru';   // DEV
// export const apiUrl = 'https://api.vitrinabot.online';        // PROD

export const sidebarMenuLinks = [
  { id: 1, pathName: '/statistic', text: 'Статистика', icon: <StatisticsSVG /> },
  { id: 2, pathName: '/users', text: 'Пользователи', icon: <UserSVG /> },
  { id: 3, pathName: '/ads', text: 'Объявления', icon: <ListSVG /> },
  { id: 4, pathName: '/orders', text: 'Заказы', icon: <BoxSVG /> }
];

export const sidebarSubMenuLinks = [
  {
    id: 1,
    text: 'Настройки',
    icon: <SettingsSVG />,
    categories: [
      {
        id: 1,
        text: 'Настройки сообщества',
        pathName: '/settings-client'
      },
      {
        id: 2,
        text: 'Бот работы с объявлениями',
        pathName: '/settings-catalog'
      },
      {
        id: 3,
        text: 'Навигационный бот',
        pathName: '/settings-navbot'
      }
    ]
  }
];
