import SERVICE_API from 'api';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getChannelsAction,
  createChannelAction,
  editChannelAction,
  deleteChannelAction,
  changePositionChannelAction
} from 'store/actions/channelsActions';
import { setChannels, addChannel, editChannel, deleteChannel } from 'store/slices/channelSlice';

function* fetchChannelSaga(action) {
  try {
    const response = yield call(() => SERVICE_API.ChannelAPI.getChannels(action.payload.id));
    yield put(setChannels(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* createChannelSaga(action) {
  try {
    const response = yield call(() => SERVICE_API.ChannelAPI.createChannel(action.payload.body));
    yield put(addChannel(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* editChannelSaga(action) {
  try {
    const { body, navLinkId } = action.payload;

    const response = yield call(() => SERVICE_API.ChannelAPI.editChannel({ body, navLinkId }));

    if (response.data) {
      yield put(editChannel({ changedChannel: response.data }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteChannelSaga(action) {
  const { navLinkId } = action.payload;
  try {
    const response = yield call(() => SERVICE_API.ChannelAPI.deleteChannel(navLinkId));

    if (response.data) {
      yield put(deleteChannel(navLinkId));
    }
  } catch (error) {
    console.log(error);
  }
}

function* changePositionChannelSaga(action) {
  const { navId, newOrder } = action.payload;

  try {
    const response = yield call(() =>
      SERVICE_API.ChannelAPI.changePositionChannel({ navId, newOrder })
    );

    yield put(setChannels(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* channelSaga() {
  yield takeEvery(getChannelsAction, fetchChannelSaga);
  yield takeEvery(createChannelAction, createChannelSaga);
  yield takeEvery(editChannelAction, editChannelSaga);
  yield takeEvery(deleteChannelAction, deleteChannelSaga);
  yield takeEvery(changePositionChannelAction, changePositionChannelSaga);
}
