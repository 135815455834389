import API from 'api/rest';

class NavigatorAPI {
  getNavigatorInfo = (clientId) => API.get(`/api/navigator/${clientId}`);

  createNavigator = (navBotData) => API.post('/api/navigator', navBotData);

  editNavigatorInfo = ({ navId, info }) => API.put(`/api/navigator/${navId}`, info);
}

export default new NavigatorAPI();
