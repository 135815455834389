import API from 'api/rest';

class OptionsAPI {
  getCity = (filterStr) => API.get(`/api/city?filter=${filterStr}`);

  getAvailableUser = ({ botId, filterStr }) =>
    API.get(`/api/user/admin/list/${botId}?filter=${filterStr}`);
}

export default new OptionsAPI();
