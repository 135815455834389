/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  page: 1,
  quantity: 5,
  search: '',
  seller: null,
  buyer: null,
  curSortOption: { value: 0, direction: 'asc', fieldName: 'date' }
};

export const orderFilterSlice = createSlice({
  name: Slices.orderFilter,
  initialState,
  reducers: {
    setCurPage: (state, action) => {
      state.page = action.payload;
    },
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setSeller: (state, action) => {
      state.seller = action.payload;
      state.page = 1;
    },
    setBuyer: (state, action) => {
      state.buyer = action.payload;
      state.page = 1;
    },

    setCurSortOption: (state, action) => {
      state.curSortOption = action.payload;
      state.page = 1;
    },

    resetFilters: (state) => {
      state.page = 1;
      state.search = '';
      state.seller = null;
      state.buyer = null;
    }
  }
});

export const {
  setCurPage,
  setQuantity,
  setSearch,
  setSeller,
  setBuyer,
  resetFilters,
  setCurSortOption
} = orderFilterSlice.actions;

export default orderFilterSlice.reducer;
