import React from 'react';
import s from './index.module.scss';

export const TypesPrompt = () => (
  <div className={s.container}>
    <h4 className={s.title}>Типы объявлений </h4>
    <p className={s.text}>
      Указывается цель объявления для покупателя и продавца. Например: продажа, покупка, аренда и
      другие. Пользователь, перед публикацией объявления через бот, будет указывать тип объявления.
    </p>
  </div>
);
