import { Autocomplete, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useDebounce } from 'hooks/useDebonce';
import React, { useState, useEffect } from 'react';

export const FormikAutocomplete = (props) => {
  const {
    name = '',
    label,
    onChange,
    getOptionsCallback,
    options,
    optionName,
    width = 210
  } = props;
  const [field] = useField(name);
  const form = useFormikContext();
  const [filterStr, setFilterStr] = useState('');
  const debouncedSearchValue = useDebounce(filterStr, 500);

  const onInputChange = (e, value) => {
    setFilterStr(value);
  };

  const handleChange = (e, newVal) => {
    form.setFieldValue(name, newVal);

    if (onChange) onChange(newVal);
  };

  const inputValue = field.value || null;

  useEffect(() => {
    if (debouncedSearchValue) getOptionsCallback(debouncedSearchValue);
  }, [debouncedSearchValue]);

  return (
    <Autocomplete
      clearIcon={false}
      id="combo-box-demo"
      options={options}
      getOptionLabel={(option) => option[optionName]}
      sx={{ width, backgroundColor: '#fff' }}
      value={inputValue}
      onInputChange={onInputChange}
      onChange={handleChange}
      renderInput={(params) => <TextField key={params.id} {...params} label={label} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(renderProps, option) => (
        <li {...renderProps} key={option.id}>
          {option[optionName]}
        </li>
      )}
    />
  );
};
