import { createAction } from '@reduxjs/toolkit';

export const getOrdersAction = createAction('getOrders', ({ botId, queryParams }) => ({
  payload: {
    botId,
    queryParams
  }
}));

export const getAvailableSellersForOrderAction = createAction(
  'getAvailableSellersForOrder',
  ({ botId, filterStr }) => ({
    payload: {
      botId,
      filterStr
    }
  })
);

export const getAvailableBuyersForOrderAction = createAction(
  'getAvailableBuyersForOrder',
  ({ botId, filterStr }) => ({
    payload: {
      botId,
      filterStr
    }
  })
);
