import React from 'react';
import { EditChannelMiniForm } from 'components/form/editChannelMiniForm';
import { Dialog, DialogContent } from '@mui/material';
import s from './index.module.scss';

export const ModalEditChannelMini = ({ hideModal, rowData }) => (
  <Dialog open onClose={hideModal} aria-labelledby="form-dialog-title">
    <DialogContent>
      <div className={s.content}>
        <h4 className={s.title}>Редактирование канала</h4>
        <EditChannelMiniForm hideModal={hideModal} rowData={rowData} />
      </div>
    </DialogContent>
  </Dialog>
);
