import { createAction } from '@reduxjs/toolkit';

export const getCategoriesAction = createAction('getCategories', (id) => ({
  payload: {
    id
  }
}));

export const addCategoryAction = createAction('addCategory', ({ id, title }) => ({
  payload: {
    id,
    title
  }
}));

export const deleteCategoryAction = createAction('deleteCategory', ({ botId, id }) => ({
  payload: {
    botId,
    id
  }
}));

export const editCategoryAction = createAction('editCategory', ({ botId, category }) => ({
  payload: {
    botId,
    category
  }
}));

export const changePositionCategoryAction = createAction(
  'changePositionCategory',
  ({ botId, newOrder }) => ({
    payload: {
      botId,
      newOrder
    }
  })
);
