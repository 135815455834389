import SERVICE_API from 'api';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getQuestionsAction,
  addQuestionAction,
  deleteQuestionAction,
  editQuestionAction,
  changePositionQuestionAction
} from 'store/actions/questionsActions';
import {
  setQuestions,
  addQuestion,
  deleteQuestion,
  editQuestion
} from 'store/slices/questionSlice';

function* fetchQuestionSaga(action) {
  try {
    const response = yield call(() => SERVICE_API.QuestionAPI.getQuestions(action.payload.navId));

    if (response?.data) {
      yield put(setQuestions(response.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* addQuestionSaga(action) {
  try {
    const response = yield call(() => SERVICE_API.QuestionAPI.addQuestion(action.payload.question));

    if (response?.data) {
      yield put(addQuestion(response.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteQuestionSaga(action) {
  try {
    const response = yield call(() => SERVICE_API.QuestionAPI.deleteQuestion(action.payload.id));

    if (response?.data) {
      yield put(deleteQuestion({ deletedId: action.payload.id }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* editQuestionSaga(action) {
  const { id, question } = action.payload;

  try {
    const response = yield call(() => SERVICE_API.QuestionAPI.editQuestion({ id, question }));

    if (response?.data) {
      yield put(editQuestion({ changedQuestion: response.data }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* changePositionQuestionSaga(action) {
  const { navId, newOrder } = action.payload;

  try {
    const response = yield call(() =>
      SERVICE_API.QuestionAPI.changePositionQuestion({ navId, newOrder })
    );

    yield put(setQuestions(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* questionSaga() {
  yield takeEvery(getQuestionsAction, fetchQuestionSaga);
  yield takeEvery(addQuestionAction, addQuestionSaga);
  yield takeEvery(deleteQuestionAction, deleteQuestionSaga);
  yield takeEvery(editQuestionAction, editQuestionSaga);
  yield takeEvery(changePositionQuestionAction, changePositionQuestionSaga);
}
