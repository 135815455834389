import API from 'api/rest';

class ChannelAPI {
  getChannels = (navId) => API.get(`/api/nav-link/${navId}`);

  createChannel = (body) => API.post('/api/nav-link', body);

  editChannel = ({ body, navLinkId }) => API.put(`/api/nav-link/${navLinkId}`, body);

  deleteChannel = (navLinkId) => API.delete(`/api/nav-link/${navLinkId}`);

  changePositionChannel = ({ navId, newOrder }) =>
    API.post(`/api/nav-link/change-position/${navId}`, newOrder);
}

export default new ChannelAPI();
