import SERVICE_API from 'api';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getUsersAction } from 'store/actions/userActions';
import { setUsers, setTotal } from 'store/slices/userSlice';

function* fetchUsersSaga(action) {
  const { botId, queryParams } = action.payload;
  try {
    const response = yield call(() => SERVICE_API.UserAPI.getUsers({ botId, queryParams }));

    yield put(setUsers(response?.data?.users));
    yield put(setTotal(response?.data?.meta?.total));
    // console.log('fetchUsersSaga ', response);
  } catch (error) {
    console.log(error);
  }
}

export function* userSaga() {
  yield takeEvery(getUsersAction, fetchUsersSaga);
}
