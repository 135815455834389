export const AppPaths = {
  statistic: '/statistic',
  users: '/users',
  ads: '/ads',
  adNew: '/adNew',
  ad: '/ads/:id',
  orders: '/orders',
  order: '/orders/:id',
  settingsClient: '/settings-client',
  settingsCatalog: '/settings-catalog',
  settingsNavBot: '/settings-navbot',
  login: '/login',
  NotFound: '*'
};

export const PrivateAppPath = [
  '/settings-client',
  '/settings-catalog',
  '/settings-navbot',
  '/ads',
  '/adNew',
  '/ads/:id',
  '/users',
  '/orders/:id'
];
