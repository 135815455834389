const sortOptions = [
  {
    value: 0,
    direction: 'asc',
    fieldName: 'date'
  },
  {
    value: 1,
    direction: 'desc',
    fieldName: 'date'
  },
  {
    value: 2,
    direction: 'asc',
    fieldName: 'price'
  },
  {
    value: 3,
    direction: 'desc',
    fieldName: 'price'
  },
  {
    value: 4,
    direction: 'asc',
    fieldName: 'quantity'
  },
  {
    value: 5,
    direction: 'desc',
    fieldName: 'quantity'
  }
];

export const getCurSortOrderOption = (fieldName, curSortOption) => {
  const fieldNameMatch = sortOptions.filter((item) => item.fieldName === fieldName);

  if (fieldNameMatch.find((item) => item.fieldName === curSortOption.fieldName)) {
    return fieldNameMatch.find((item) => item.direction !== curSortOption.direction);
  }

  return fieldNameMatch.find((item) => item.direction === 'asc');
};
