import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { MenuDropdown } from './menuDropdown';
import s from './index.module.scss';

export const Header = ({ toggleSidebar }) => (
  <div className={s.header}>
    <div className={s.header__inner}>
      <IconButton onClick={toggleSidebar} className={s.button__burger}>
        <MenuIcon />
      </IconButton>
      <MenuDropdown />
    </div>
  </div>
);
