import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { removeNotification } from 'store/slices/notificationsSlice';

export const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = (key) => (
    <IconButton aria-label="close" onClick={() => closeSnackbar(key)}>
      <CloseIcon />
    </IconButton>
  );

  useEffect(() => {
    notifications.forEach(({ message, options }) => {
      // console.log(options.variant);
      enqueueSnackbar(message, {
        ...options,
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        sx: {
          '& .SnackbarContent-root': {
            backgroundColor: options.variant === 'success' && '#1cc700'
          }
        },
        action,
        onExited: (_, key) => {
          dispatch(removeNotification(key));
          closeSnackbar(key);
        }
      });
    });
  }, [notifications, enqueueSnackbar]);

  return null;
};
