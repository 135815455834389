import React from 'react';
import screen1 from 'resources/images/BotTokenPromptScreen.png';
import s from './index.module.scss';

export const BotTokenPrompt = () => (
  <div className={s.container}>
    <h4 className={s.title}>Токен бота</h4>
    <p className={s.text}>
      Это уникальная строка из символов, которая нужна, чтобы установить подлинность бота в
      административной панели. Его следует скопировать и не терять. В данное поле введите строку из
      символов, которая была сгенерирована при создании бота работы с объявлениями через BotFather.
    </p>

    <div className={s.screen__container}>
      <img src={screen1} alt="screen" />
    </div>
  </div>
);
