import React from 'react';
import loader from 'resources/images/loader.gif';
import s from './index.module.scss';

export const Loader = () => (
  <div className={s.loader__box}>
    <div className={s.loader}>
      <img src={loader} alt="" />
    </div>
  </div>
);
