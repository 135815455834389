import API from 'api/rest';

class TypePostAPI {
  getTypesPost = (botId) => API.get(`/api/typePost/${botId}`);

  addTypePost = ({ botId, title }) => API.post(`/api/typePost/${botId}`, { title, botId });

  deleteTypePost = ({ botId, id }) => API.delete(`/api/typePost/${botId}`, { botId, id });

  editTypePost = ({ botId, typePost }) => API.put(`/api/typePost/${botId}`, typePost);

  changePositionTypePost = ({ botId, newOrder }) =>
    API.post(`/api/typePost/change-position/${botId}`, newOrder);
}

export default new TypePostAPI();
