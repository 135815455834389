import API from 'api/rest';

class PostAPI {
  getPosts = ({ botId, queryParams = '' }) => API.get(`/api/post/${botId}?${queryParams}`);

  getOnePost = (id) => API.get(`/api/post/get-post/${id}`);

  editOnePost = ({ id, body }) => API.put(`/api/post/${id}`, body);

  createPost = (body) => API.post('/api/post', body);

  raisePost = (id) => API.post(`/api/post/raise/${id}`);

  publishPost = ({ id, body }) => API.post(`/api/post/publication/${id}`, body);
}

export default new PostAPI();
