import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { IconButton } from '@mui/material';
import s from './index.module.scss';

export const PromptButton = ({ callbackHandler }) => (
  <div>
    <IconButton
      aria-label="delete"
      color="secondary"
      className={s.button__prompt}
      onClick={callbackHandler}
    >
      <ErrorOutlineIcon />
    </IconButton>
  </div>
);
