import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrl } from 'constant/data';
import DoneIcon from '@mui/icons-material/Done';
import Chip from '@mui/material/Chip';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableFooter,
  TablePagination,
  Avatar
} from '@mui/material';
import { convertDate } from 'utils/convert';
import { setCurPage, setQuantity } from 'store/slices/userFilterSlice';
import s from './index.module.scss';

export const UsersTable = () => {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.userFilter.page);
  const quantity = useSelector((state) => state.userFilter.quantity);
  const total = useSelector((state) => state.user.total);
  const users = useSelector((state) => state.user.users);
  // console.log('UsersTable', users);

  const handleChangePage = (event, newPage) => {
    dispatch(setCurPage(newPage + 1));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setQuantity(+event.target.value));
    dispatch(setCurPage(1));
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table sx={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>Авaтар</TableCell>
              <TableCell>Пользователь</TableCell>
              <TableCell>Объявления</TableCell>
              <TableCell>Покупки</TableCell>
              <TableCell>Продажи</TableCell>
              <TableCell>Дата регистрации</TableCell>
              <TableCell>Статус</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {users.length > 0 &&
              users.map((item) => (
                <TableRow key={item.id} className={s.row}>
                  <TableCell>
                    <Avatar src={`${apiUrl}/api/${item?.avatar}`} />
                  </TableCell>
                  <TableCell>
                    <a
                      className={s.user__link}
                      href={`https://t.me/${item?.username}`}
                      target="blank"
                    >{`@${item?.username}`}</a>
                    <br /> <p>{item?.phone}</p>
                  </TableCell>
                  <TableCell>
                    <p className={s.box_number}>{item?.postsCount}</p>
                  </TableCell>
                  <TableCell>
                    <p className={s.box_number}>{item?.purchasesCount}</p>
                  </TableCell>
                  <TableCell>
                    <p className={s.box_number}>{item?.salesCount}</p>
                  </TableCell>
                  <TableCell>{convertDate(item.createAt)}</TableCell>
                  <TableCell>
                    <Chip label="Активный" icon={<DoneIcon />} variant="outlined" color="primary" />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 8, 20]}
                count={total || 0}
                rowsPerPage={quantity}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
