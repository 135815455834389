import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertPostFilterParams } from 'utils/convert';
import { getPostsAction } from 'store/actions/postActions';
import { useNavigate } from 'react-router';
import { AdsTable } from 'components/ads/adsTable';
import { AdsFilterForm } from 'components/form/adsFilterForm';
import { setPostCreationStatus } from 'store/slices/postSlice';
import { Button } from '@mui/material';
import s from './index.module.scss';

export const AdsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const postCreationStatus = useSelector((state) => state.post.postCreationStatus);
  const botId = useSelector((state) => state.bot.tokenId);
  const page = useSelector((state) => state.postFilter.page);
  const quantity = useSelector((state) => state.postFilter.quantity);
  const categoriesId = useSelector((state) => state.postFilter.categoriesId);
  const city = useSelector((state) => state.postFilter.city);
  const author = useSelector((state) => state.postFilter.author);
  const minPrice = useSelector((state) => state.postFilter.minPrice);
  const maxPrice = useSelector((state) => state.postFilter.maxPrice);
  const search = useSelector((state) => state.postFilter.search);
  const curSortOption = useSelector((state) => state.postFilter.curSortOption);

  const queryParams = convertPostFilterParams(
    page,
    quantity,
    categoriesId,
    city,
    author,
    minPrice,
    maxPrice,
    search,
    curSortOption
  );
  // console.log('ads ', queryParams);

  useEffect(() => {
    if (botId) dispatch(getPostsAction({ botId, queryParams }));
  }, [botId, queryParams, categoriesId, dispatch]);

  useEffect(() => {
    if (botId && postCreationStatus === 'success') {
      dispatch(getPostsAction({ botId, queryParams }));
      dispatch(setPostCreationStatus(''));
    }
  }, [postCreationStatus]);

  const handleNavigate = () => {
    navigate('/adNew');
  };

  return (
    <div className={s.container}>
      <AdsFilterForm />
      <div className={s.button__new__ad__wrapper}>
        <Button variant="contained" className={s.button__new__ad} onClick={handleNavigate}>
          создать объявление
        </Button>
      </div>
      <AdsTable />
    </div>
  );
};
