import SERVICE_API from 'api';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getAvailableSellersForOrderAction,
  getAvailableBuyersForOrderAction,
  getOrdersAction
} from 'store/actions/orderActions';
import {
  setAvailableSellersForOrder,
  setAvailableBuyersForOrder,
  setOrders,
  setTotal
} from 'store/slices/orderSlice';

function* fetchOrdersSaga(action) {
  const { botId, queryParams } = action.payload;
  try {
    const response = yield call(() => SERVICE_API.OrderAPI.getOrders({ botId, queryParams }));

    yield put(setOrders(response?.data?.list));
    yield put(setTotal(response?.data?.meta?.total));
    // console.log('fetchOrdersSaga ', response);
  } catch (error) {
    console.log(error);
  }
}

function* getAvailableSellersForOrderSaga(action) {
  const { botId, filterStr } = action.payload;
  try {
    const response = yield call(() =>
      SERVICE_API.OptionsAPI.getAvailableUser({ botId, filterStr })
    );

    if (response?.data) yield put(setAvailableSellersForOrder(response?.data));
  } catch (error) {
    console.log(error);
  }
}

function* getAvailableBuyersForOrderSaga(action) {
  const { botId, filterStr } = action.payload;
  try {
    const response = yield call(() =>
      SERVICE_API.OptionsAPI.getAvailableUser({ botId, filterStr })
    );

    if (response?.data) yield put(setAvailableBuyersForOrder(response?.data));
  } catch (error) {
    console.log(error);
  }
}

export function* orderSaga() {
  yield takeEvery(getOrdersAction, fetchOrdersSaga);
  yield takeEvery(getAvailableSellersForOrderAction, getAvailableSellersForOrderSaga);
  yield takeEvery(getAvailableBuyersForOrderAction, getAvailableBuyersForOrderSaga);
}
