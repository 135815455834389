import React from 'react';
import { CreateQuestionForm } from 'components/form/createQuestionForm';
import { Dialog, DialogContent } from '@mui/material';
import s from './index.module.scss';

export const ModalCreateQuestion = ({ hideModal }) => (
  <Dialog open onClose={hideModal} aria-labelledby="form-dialog-title">
    <DialogContent>
      <div className={s.content}>
        <h4 className={s.title}>Добавление вопроса</h4>
        <CreateQuestionForm hideModal={hideModal} />
      </div>
    </DialogContent>
  </Dialog>
);
