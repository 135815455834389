import API from 'api/rest';

class ChannelMiniAPI {
  getChannelsMini = (clientId) => API.get(`/api/channel/${clientId}`);

  createChannelMini = ({ clientId, channelMiniData }) =>
    API.post(`/api/channel/${clientId}`, channelMiniData);

  editChannelMini = ({ clientId, channelMiniData }) =>
    API.put(`/api/channel/${clientId}`, channelMiniData);

  deleteChannelMini = ({ clientId, channelMiniData }) =>
    API.delete(`/api/channel/${clientId}`, channelMiniData);
}

export default new ChannelMiniAPI();
