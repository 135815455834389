import { createAction } from '@reduxjs/toolkit';

export const getChannelsMiniAction = createAction('getChannelsMini', (clientId) => ({
  payload: {
    clientId
  }
}));

export const addChannelMiniAction = createAction(
  'addChannelMini',
  ({ clientId, channelMiniData }) => ({
    payload: {
      clientId,
      channelMiniData
    }
  })
);

export const editChannelMiniAction = createAction(
  'editChannelMini',
  ({ clientId, channelMiniData }) => ({
    payload: {
      clientId,
      channelMiniData
    }
  })
);

export const deleteChannelMiniAction = createAction(
  'deleteChannelMini',
  ({ clientId, channelMiniData }) => ({
    payload: {
      clientId,
      channelMiniData
    }
  })
);
