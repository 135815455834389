import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editQuestionAction } from 'store/actions/questionsActions';
import s from './index.module.scss';

export const Activator = ({ row }) => {
  const dispatch = useDispatch();
  const navId = useSelector((state) => state.navigator.navigatorInfo?.token);
  const { active } = row;

  const handleInActivate = () => {
    const question = { navId, active: false };

    dispatch(editQuestionAction({ id: row.id, question }));
  };

  const handleActivate = () => {
    const question = { navId, active: true };

    dispatch(editQuestionAction({ id: row.id, question }));
  };

  return (
    <>
      {active ? (
        <Button
          variant="outlined"
          className={s.button__reset}
          color="secondary"
          onClick={handleInActivate}
        >
          отключить
        </Button>
      ) : (
        <Button
          variant="outlined"
          className={s.button__reset}
          color="secondary"
          onClick={handleActivate}
        >
          включить
        </Button>
      )}
    </>
  );
};
