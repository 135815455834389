import API from 'api/rest';

class BotAPI {
  getBotInfo = (clientId) => API.get(`/api/bot/${clientId}`);

  createBot = (botData) => API.post('/api/bot', botData);

  editBot = ({ botData, tokenId }) => API.put(`/api/bot/${tokenId}`, botData);
}

export default new BotAPI();
