import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

export const PrivateRoute = () => {
  const location = useLocation();
  const clientId = useSelector((state) => state.auth.clientId);
  // const authCredential = JSON.parse(localStorage.getItem('authCredential'));

  if (!clientId) {
    return <Navigate to={'/login'} state={{ from: location }} />;
  }

  return <Outlet />;
};
