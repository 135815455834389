/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  clientId: '',
  clientFullName: '',
  clientPhone: '',
  clientEmail: '',
  clientCommunityName: '',

  loading: false
};

export const authSlice = createSlice({
  name: Slices.auth,
  initialState,
  reducers: {
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },

    setClientFullName: (state, action) => {
      state.clientFullName = action.payload;
    },

    setClientPhone: (state, action) => {
      state.clientPhone = action.payload;
    },
    setClientEmail: (state, action) => {
      state.clientEmail = action.payload;
    },
    setClientCommunityName: (state, action) => {
      state.clientCommunityName = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    logout: (state) => {
      state.clientId = '';
      state.clientFullName = '';
      state.clientPhone = '';
      state.clientEmail = '';
      state.clientCommunityName = '';
    }
  }
});

export const {
  setClientId,
  setClientFullName,
  setClientPhone,
  setClientEmail,
  setClientCommunityName,
  setLoading,
  logout
} = authSlice.actions;

export default authSlice.reducer;
