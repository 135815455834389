import React from 'react';
import s from './index.module.scss';

export const ClientCommunity = () => (
  <div className={s.container}>
    <h4 className={s.title}>Название сообщества</h4>
    <p className={s.text}>
      Название вашего тематического сообщества в социальных сетях. Например: People&boats, Барахолка
      Саратова и т.д. Отображается в административной панели. Может отображаться вместо логотипа,
      если картинка не загружена.
    </p>
  </div>
);
