import React, { useState } from 'react';
import noPhoto from 'resources/images/noPhoto.png';
import clsx from 'clsx';
import s from './index.module.scss';

export const PreviewPhoto = ({ file, customClassName = '', customNoPhoto }) => {
  const [preview, setPreview] = useState(null);

  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }

  return (
    <div className={clsx(s.image__container, customClassName)}>
      <img src={preview || customNoPhoto || noPhoto} alt="preview" />
    </div>
  );
};
