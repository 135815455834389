import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { convertDate } from 'utils/convert';
import { getCurSortOption } from 'utils/sort';
import { setCurPage, setCurSortOption, setQuantity } from 'store/slices/postFilterSlice';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableFooter,
  TablePagination
} from '@mui/material';
import s from './index.module.scss';

export const AdsTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const page = useSelector((state) => state.postFilter.page);
  const quantity = useSelector((state) => state.postFilter.quantity);
  const total = useSelector((state) => state.post.total);
  const posts = useSelector((state) => state.post.posts);
  const curSortOption = useSelector((state) => state.postFilter.curSortOption);

  const handleChangePage = (event, newPage) => {
    dispatch(setCurPage(newPage + 1));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setQuantity(+event.target.value));
    dispatch(setCurPage(1));
  };

  const handleSort = (fieldName) => {
    const newSortOption = getCurSortOption(fieldName, curSortOption);
    dispatch(setCurSortOption(newSortOption));
  };

  const handleNavigate = (id) => {
    navigate(`/ads/${id}`);
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table sx={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>Автор</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Город</TableCell>
              <TableCell>Категория</TableCell>
              <TableCell onClick={() => handleSort('date')}>
                <div className={s.cell}>
                  <p>Дата</p>
                  {curSortOption.fieldName === 'date' && (
                    <span className={s.cell__icon}>
                      {curSortOption.direction === 'asc' ? (
                        <VerticalAlignBottomIcon />
                      ) : (
                        <VerticalAlignTopIcon />
                      )}
                    </span>
                  )}
                </div>
              </TableCell>
              <TableCell onClick={() => handleSort('price')}>
                <div className={s.cell}>
                  <p>Цена</p>
                  {curSortOption.fieldName === 'price' && (
                    <span className={s.cell__icon}>
                      {curSortOption.direction === 'asc' ? (
                        <VerticalAlignBottomIcon />
                      ) : (
                        <VerticalAlignTopIcon />
                      )}
                    </span>
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {posts.length > 0 &&
              posts.map((item) => (
                <TableRow key={item.id} className={s.row} onClick={() => handleNavigate(item.id)}>
                  <TableCell sx={{ width: 150 }}>
                    <a
                      onClick={(e) => e.stopPropagation()}
                      className={s.user__link}
                      href={`https://t.me/${item?.user?.username}`}
                      target="blank"
                    >{`@${item?.user?.username}`}</a>
                  </TableCell>
                  <TableCell sx={{ width: 300 }}>{item.title}</TableCell>
                  <TableCell>{item.city ? item.city : 'не указано'}</TableCell>
                  <TableCell>{item.category ? item.category : 'не указано'}</TableCell>
                  <TableCell>{convertDate(item.createAt)}</TableCell>
                  <TableCell>{item.price ? item.price : 'не указано'}</TableCell>
                </TableRow>
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 8, 20]}
                count={total || 0}
                rowsPerPage={quantity}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
