import React from 'react';
import { useFormikContext, useField } from 'formik';
import { FormControlLabel, Switch, Typography } from '@mui/material';

export const FormikSwitch = (props) => {
  const { name = '', onChange, label, ...other } = props;
  const [field] = useField(name);
  const form = useFormikContext();

  const handleChange = (e) => {
    form.setFieldValue(name, e.target.checked);
    if (onChange) onChange(e.target.checked);
  };

  const inputValue = field.value || false;

  const evalProps = {
    ...field,
    ...other,
    checked: inputValue,
    id: name,
    onChange: handleChange
  };

  return (
    <>
      <FormControlLabel
        value="start"
        control={<Switch color="primary" {...evalProps} />}
        label={
          <Typography
            sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '0.9375rem' }}
          >
            {label}
          </Typography>
        }
        labelPlacement="start"
      />
    </>
  );
};
