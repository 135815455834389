import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { ClientChannelsMini } from 'components/settings/clientChannelsMini';
import { CreateWebForm } from 'components/form/createWebForm';
import { useSelector } from 'react-redux';
import { EditWebForm } from 'components/form/editWebForm';
import { SettingsClientMain } from 'components/settings/settingsClientMain';
import s from './index.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className={s.tabpanel__container}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const SettingsClient = () => {
  const [value, setValue] = React.useState(0);
  const isWebExists = useSelector((state) => state.web.isWebExists);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={s.container}>
      <h1 className={s.page__title}>Настройки сообщества</h1>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="общие настройки" {...a11yProps(0)} />
            <Tab label="каналы" {...a11yProps(1)} />
            <Tab label="web сайт" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SettingsClientMain />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ClientChannelsMini />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CreateWebForm />
        </TabPanel>
      </Box>
    </div>
  );
};
