import { AdCreateForm } from 'components/form/adCreateForm';
import React from 'react';
import s from './index.module.scss';

export const AdNew = () => (
  <div className={s.container}>
    <h1 className={s.title}>Создание объявления</h1>
    <AdCreateForm />
  </div>
);
