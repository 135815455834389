import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import EuroIcon from '@mui/icons-material/Euro';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import React from 'react';

export function getCurrencyIcon(currency) {
  if (!currency) {
    return '';
  }
  const currencyIcon = new Map([
    ['RUB', <CurrencyRubleIcon />],
    ['EUR', <EuroIcon />],
    ['USD', <AttachMoneyIcon />]
  ]);

  return currencyIcon.get(currency) || '';
}
