import SERVICE_API from 'api';
import { call, put, takeEvery } from 'redux-saga/effects';
import { editNavigatorAction, createNavigatorAction } from 'store/actions/navigatorActions';
import {
  setNavigatorInfo,
  setIsNavigatorExists,
  setNavigatorError
} from 'store/slices/navigatorSlice';
import { setNotification } from 'store/slices/notificationsSlice';

function* editNavigatorSaga(action) {
  try {
    yield put(setNavigatorError(''));
    const { navId, info } = action.payload;
    const response = yield call(() => SERVICE_API.NavigatorAPI.editNavigatorInfo({ navId, info }));
    yield put(setNavigatorInfo(response.data));
    yield put(
      setNotification({
        message: 'Настройки сохранены',
        options: {
          variant: 'success'
        }
      })
    );
  } catch (error) {
    console.log(error);

    yield put(setNavigatorError(error?.response?.data?.error));
  }
}

function* createNavigatorSaga(action) {
  try {
    yield put(setNavigatorError(''));
    const response = yield call(() =>
      SERVICE_API.NavigatorAPI.createNavigator(action.payload.navBotData)
    );

    if (response.data) {
      const { link, mainDesc, miniDesc, title, token, typeNavigation } = response.data;
      yield put(setIsNavigatorExists(true));
      yield put(setNavigatorInfo({ link, mainDesc, miniDesc, title, token, typeNavigation }));
      yield put(
        setNotification({
          message: 'Настройки сохранены',
          options: {
            variant: 'success'
          }
        })
      );
    }
  } catch (error) {
    console.log(error);

    yield put(setNavigatorError(error?.response?.data?.error));
  }
}

export function* navigatorSaga() {
  yield takeEvery(editNavigatorAction, editNavigatorSaga);
  yield takeEvery(createNavigatorAction, createNavigatorSaga);
}
