import { createAction } from '@reduxjs/toolkit';

export const loginAction = createAction('login', (authCredential) => ({
  payload: {
    authCredential
  }
}));

export const editClientAction = createAction('editClient', ({ clientInfo, clientId }) => ({
  payload: {
    clientInfo,
    clientId
  }
}));
