import React from 'react';
import { Button, Dialog, DialogContent } from '@mui/material';
import s from './index.module.scss';

export const ModalConfirm = ({ hideModal, question = 'Удалить?', confirmFunc }) => {
  const handleConfirmFunc = () => {
    confirmFunc();
    hideModal();
  };

  return (
    <Dialog open onClose={hideModal} aria-labelledby="form-dialog-title">
      <DialogContent>
        <div className={s.content}>
          <h4 className={s.title}>{question}</h4>
          <div className={s.button__group}>
            <Button
              onClick={handleConfirmFunc}
              variant="contained"
              className={s.button__save}
              type="submit"
              size="large"
              fullWidth
            >
              да
            </Button>
            <Button
              onClick={hideModal}
              fullWidth
              variant="outlined"
              className={s.button__reset}
              color="secondary"
              size="large"
            >
              нет
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
