import { createAction } from '@reduxjs/toolkit';

export const getWebDataAction = createAction('getWebData', (clientId) => ({
  payload: {
    clientId
  }
}));

export const createWebDataAction = createAction('createWebData', ({ clientId, body }) => ({
  payload: {
    clientId,
    body
  }
}));

export const editWebDataAction = createAction('editWebData', ({ id, body }) => ({
  payload: {
    id,
    body
  }
}));

export const deleteWebLogoAction = createAction('getWebLogo', (webId) => ({
  payload: {
    webId
  }
}));
