/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  isWebExists: false,
  webId: '',
  subdomen: '',
  logo: ''
};

export const webSlice = createSlice({
  name: Slices.web,
  initialState,
  reducers: {
    setIsWebExists: (state, action) => {
      state.isWebExists = action.payload;
    },

    setLogo: (state, action) => {
      state.logo = action.payload;
    },

    setWebId: (state, action) => {
      state.webId = action.payload;
    },
    setSubdomen: (state, action) => {
      state.subdomen = action.payload;
    }
  }
});

export const { setLogo, setIsWebExists, setWebId, setSubdomen } = webSlice.actions;

export default webSlice.reducer;
