import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { routes } from 'components/router/routes';
import { generateAppRoutes } from 'components/router/utils';
import { PrivateAppPath } from './constants';
import { PrivateRoute } from './privateRoute';

export const MainRouter = () => {
  const appRoutes = generateAppRoutes(routes);
  return (
    <>
      <Routes>
        {appRoutes.map((route) => {
          const Component = () => route.component(route);
          return PrivateAppPath.includes(route.path) ? (
            <Route key={route.path} element={<PrivateRoute />}>
              <Route index={route.index} path={route.path} element={<Component />} />
            </Route>
          ) : (
            <Route key={route.path} index={route.index} path={route.path} element={<Component />} />
          );
        })}

        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </>
  );
};
