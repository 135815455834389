import API from 'api/rest';
import axios from 'axios';
import { apiUrl } from 'constant/data';

class CategoryAPI {
  getCategories = (botId) => API.get(`/api/category/${botId}`);

  addCategory = ({ botId, title }) => API.post(`/api/category/${botId}`, { title, botId });

  deleteCategory = ({ botId, id }) => API.delete(`/api/category/${botId}`, { botId, id });
  // deleteCategory = ({ botId, id }) => API.delete(`/api/category/${botId}`, { data: { botId, id } });

  // TODO УБРАТЬ
  // deleteCategory = ({ botId, id }) =>
  //   axios.request({
  //     url: `${apiUrl}/api/category/${botId}`,
  //     method: 'DELETE',
  //     data: { botId, id },
  //     headers: {
  //       // 'Access-Control-Allow-Origin': '*',
  //       // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Z-Key',
  //       // 'Content-Type': 'application/json',
  //       'Access-Control-Allow-Methods': 'DELETE, GET, HEAD, POST, PUT, OPTIONS, TRACE',

  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Credentials': true,
  //       'Access-Control-Allow-Origin': `${window.location.protocol}//${window.location.host}`
  //     }
  //   });

  editCategory = ({ botId, category }) => API.put(`/api/category/${botId}`, category);

  changePositionCategory = ({ botId, newOrder }) =>
    API.post(`/api/category/change-position/${botId}`, newOrder);
}

export default new CategoryAPI();
