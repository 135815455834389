import SERVICE_API from 'api';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getAvailableCitiesForPostAction,
  getAvailableUsersForPostAction,
  getPostsAction,
  getOnePostAction,
  editOnePostAction,
  raisePostAction,
  publishPostAction,
  createPostAction
} from 'store/actions/postActions';
import { setNotification } from 'store/slices/notificationsSlice';
import {
  setAvailableCitiesForPosts,
  setAvailableUsersForPosts,
  setPosts,
  setTotal,
  setPostsTitle,
  setPostsCity,
  setTypePost,
  setPostsPhotos,
  setPostsOldFiles,
  setPostsPrice,
  setPostsCurrency,
  setPostsUserName,
  setPostsCategory,
  setPostsDesc,
  setPostAddress,
  setPostsPublished,
  setPostCreationStatus
} from 'store/slices/postSlice';

function* fetchPostsSaga(action) {
  const { botId, queryParams } = action.payload;

  try {
    const response = yield call(() => SERVICE_API.PostAPI.getPosts({ botId, queryParams }));

    yield put(setPosts(response?.data?.list));
    yield put(setTotal(response?.data?.meta?.total));
  } catch (error) {
    console.log(error);
  }
}

function* getPostSaga(action) {
  try {
    const response = yield call(() => SERVICE_API.PostAPI.getOnePost(action.payload.id));

    yield put(setPostsTitle(response?.data?.title));
    yield put(setPostsCity(response?.data?.city));
    yield put(setTypePost(response?.data?.typePost));
    yield put(setPostsDesc(response?.data?.description));
    yield put(setPostAddress(response?.data?.address));
    yield put(setPostsPhotos(response?.data?.photos));
    yield put(setPostsPrice(response?.data?.price));
    yield put(setPostsCurrency(response?.data?.currency));
    yield put(setPostsUserName(response?.data?.user?.username));
    yield put(setPostsCategory(response?.data?.category));
    yield put(setPostsPublished(response?.data?.published));
    yield put(setPostsOldFiles(response?.data?.documents));
  } catch (error) {
    console.log(error);
  }
}

function* editPostSaga(action) {
  const { id, body } = action.payload;
  try {
    const response = yield call(() => SERVICE_API.PostAPI.editOnePost({ id, body }));

    yield put(setPostsTitle(response?.data?.title));
    yield put(setPostsDesc(response?.data?.description));
    yield put(setPostAddress(response?.data?.address));
    yield put(setPostsPrice(response?.data?.price));
    yield put(setPostsCategory(response?.data?.category));
    yield put(setPostsPhotos(response?.data?.photos));
    yield put(setPostsOldFiles(response?.data?.documents));
  } catch (error) {
    console.log(error);
  }
}

function* createPostSaga(action) {
  try {
    const response = yield call(() => SERVICE_API.PostAPI.createPost(action.payload.body));
    // console.log('createPostSaga ', response);
    if (response.data) {
      yield put(setPostCreationStatus('success'));
      yield put(
        setNotification({
          message: 'Объявление успешно создано',
          options: {
            variant: 'success'
          }
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* getAvailableCitiesForPostSaga(action) {
  try {
    const response = yield call(() => SERVICE_API.OptionsAPI.getCity(action.payload.filterStr));

    if (response?.data) yield put(setAvailableCitiesForPosts(response?.data));
  } catch (error) {
    console.log(error);
  }
}

function* getAvailableUsersForPostSaga(action) {
  const { botId, filterStr } = action.payload;
  try {
    const response = yield call(() =>
      SERVICE_API.OptionsAPI.getAvailableUser({ botId, filterStr })
    );

    if (response?.data) yield put(setAvailableUsersForPosts(response?.data));
  } catch (error) {
    console.log(error);
  }
}

function* raisePostSaga(action) {
  try {
    const response = yield call(() => SERVICE_API.PostAPI.raisePost(action.payload.id));

    if (response?.data)
      yield put(
        setNotification({
          message: 'Объявление успешно поднято',
          options: {
            variant: 'success'
          }
        })
      );
  } catch (error) {
    console.log(error);
  }
}

function* publishPostSaga(action) {
  const { id, body } = action.payload;
  try {
    const response = yield call(() => SERVICE_API.PostAPI.publishPost({ id, body }));

    if (response?.data) yield put(setPostsPublished(response?.data?.published));
  } catch (error) {
    console.log(error);
  }
}

export function* postSaga() {
  yield takeEvery(getPostsAction, fetchPostsSaga);
  yield takeEvery(getOnePostAction, getPostSaga);
  yield takeEvery(editOnePostAction, editPostSaga);
  yield takeEvery(createPostAction, createPostSaga);
  yield takeEvery(getAvailableCitiesForPostAction, getAvailableCitiesForPostSaga);
  yield takeEvery(getAvailableUsersForPostAction, getAvailableUsersForPostSaga);
  yield takeEvery(raisePostAction, raisePostSaga);
  yield takeEvery(publishPostAction, publishPostSaga);
}
