import { createAction } from '@reduxjs/toolkit';

export const getQuestionsAction = createAction('getQuestions', (navId) => ({
  payload: {
    navId
  }
}));

export const addQuestionAction = createAction('addQuestion', (question) => ({
  payload: {
    question
  }
}));

export const deleteQuestionAction = createAction('deleteQuestion', (id) => ({
  payload: {
    id
  }
}));

export const editQuestionAction = createAction('editQuestion', ({ id, question }) => ({
  payload: {
    id,
    question
  }
}));

export const changePositionQuestionAction = createAction(
  'changePositionQuestion',
  ({ navId, newOrder }) => ({
    payload: {
      navId,
      newOrder
    }
  })
);
