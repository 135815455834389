import * as Yup from 'yup';

const linkRegExp = /^(ftp|https|http):\/\/[^ "]+$/;
const channelMiniRegExp = /^(@)[^ "]+$/;
const subdomenRegExp = /^\w+$/;

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().required('Поле обязательно *').email('некорректный email'),
  password: Yup.string().required('Поле обязательно *')
});

export const categoryValidationSchema = Yup.object().shape({
  title: Yup.string().required('Поле обязательно *')
});

export const postValidationSchema = Yup.object().shape({
  title: Yup.string().required('Поле обязательно *')
});

export const postCreateValidationSchema = Yup.object().shape({
  title: Yup.string().required('Поле обязательно *')
});

export const questionValidationSchema = Yup.object().shape({
  text: Yup.string().required('Поле обязательно *')
});

export const typePostValidationSchema = Yup.object().shape({
  title: Yup.string().required('Поле обязательно *')
});

export const channelMiniValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Поле обязательно *')
    .matches(channelMiniRegExp, 'ссылка на канал должна начинаться с @')
});

export const createChannelValidationSchema = Yup.object().shape({
  title: Yup.string().required('Поле обязательно *'),
  description: Yup.string().required('Поле обязательно *'),
  link: Yup.string()
    .required('Поле обязательно *')
    .matches(linkRegExp, 'добавьте ссылку в формате https:// или http://')
});

export const editChannelValidationSchema = Yup.object().shape({
  title: Yup.string().required('Поле обязательно *'),
  description: Yup.string().required('Поле обязательно *'),
  link: Yup.string()
    .required('Поле обязательно *')
    .matches(linkRegExp, 'добавьте ссылку в формате https:// или http://')
});

export const navigatorValidationSchema = Yup.object().shape({
  miniDesc: Yup.string().required('Поле обязательно *'),
  mainDesc: Yup.string().required('Поле обязательно *'),
  token: Yup.string().required('Поле обязательно *')
});

export const catalogMainValidationSchema = Yup.object().shape({
  token: Yup.string().required('Поле обязательно *')
});

export const clientMainValidationSchema = Yup.object().shape({
  email: Yup.string().required('Поле обязательно *').email('некорректный email'),
  phone: Yup.string().required('Поле обязательно *'),
  fullname: Yup.string().required('Поле обязательно *')
});

export const createWebValidationSchema = Yup.object().shape({
  subdomen: Yup.string()
    .required('Поле обязательно *')
    .max(40, 'не более 40 символов')
    .matches(subdomenRegExp, 'только латинские символы и цифры')
});
