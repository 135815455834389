import { createAction } from '@reduxjs/toolkit';

export const getTypesPostAction = createAction('getTypesPost', (id) => ({
  payload: {
    id
  }
}));

export const addTypePostAction = createAction('addTypePost', ({ id, title }) => ({
  payload: {
    id,
    title
  }
}));

export const deleteTypePostAction = createAction('deleteTypePost', ({ botId, id }) => ({
  payload: {
    botId,
    id
  }
}));

export const editTypePostAction = createAction('TypePost', ({ botId, typePost }) => ({
  payload: {
    botId,
    typePost
  }
}));

export const changePositionTypePostAction = createAction(
  'changePositionTypePost',
  ({ botId, newOrder }) => ({
    payload: {
      botId,
      newOrder
    }
  })
);