import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, InputAdornment, IconButton } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { loginAction } from 'store/actions/authAction';
import { loginValidationSchema } from 'utils/validation';
import { useLocation, useNavigate } from 'react-router';
import { FormContainer } from '../formContainer';
import { FormikText } from '../inputs/text';
import s from './index.module.scss';

export const LoginForm = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const clientId = useSelector((state) => state.auth.clientId);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (clientId && !location.state) {
      navigate('/settings-client');
    }

    if (clientId && location.state) {
      navigate(-1);
    }
  }, [clientId]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    email: '',
    password: ''
  };

  const onSubmit = (values) => {
    dispatch(loginAction(values));
  };

  return (
    <>
      <FormContainer
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={loginValidationSchema}
      >
        {(formik) => {
          const { errors } = formik;
          return (
            <>
              <div className={s.input__container}>
                <FormikText
                  name="email"
                  fullWidth
                  label="email"
                  error={!!errors.email}
                  helperText={errors.email}
                />

                <div className={s.input__password}>
                  <FormikText
                    name="password"
                    fullWidth
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    error={!!errors.password}
                    helperText={errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </div>

              <Button
                variant="contained"
                className={s.button__save}
                type="submit"
                size="large"
                fullWidth
              >
                войти
              </Button>
            </>
          );
        }}
      </FormContainer>
    </>
  );
};
