import SERVICE_API from 'api';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getTypesPostAction,
  addTypePostAction,
  deleteTypePostAction,
  editTypePostAction,
  changePositionTypePostAction
} from 'store/actions/typesPostActions';
import {
  addTypePost,
  deleteTypePost,
  setTypesPost,
  editTypePost
} from 'store/slices/typePostSlise';

function* fetchTypesPostSaga(action) {
  try {
    const response = yield call(() => SERVICE_API.TypePostAPI.getTypesPost(action.payload.id));
    yield put(setTypesPost(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* addTypePostSaga(action) {
  const { id: botId, title } = action.payload;

  try {
    const response = yield call(() => SERVICE_API.TypePostAPI.addTypePost({ botId, title }));

    if (response?.data) {
      const createdTypePost = {
        title: response.data.title,
        id: response.data.id
      };

      yield put(addTypePost(createdTypePost));
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteTypePostSaga(action) {
  const { botId, id } = action.payload;

  try {
    const response = yield call(() => SERVICE_API.TypePostAPI.deleteTypePost({ botId, id }));
    if (response?.data) {
      yield put(deleteTypePost({ deletedId: id }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* editTypePostSaga(action) {
  const { botId, typePost } = action.payload;

  try {
    const response = yield call(() => SERVICE_API.TypePostAPI.editTypePost({ botId, typePost }));

    if (response?.data) {
      yield put(editTypePost({ changedTypePost: response.data }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* changePositionTypePostSaga(action) {
  const { botId, newOrder } = action.payload;

  try {
    const response = yield call(() =>
      SERVICE_API.TypePostAPI.changePositionTypePost({ botId, newOrder })
    );

    yield put(setTypesPost(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* typePostSaga() {
  yield takeEvery(getTypesPostAction, fetchTypesPostSaga);
  yield takeEvery(addTypePostAction, addTypePostSaga);
  yield takeEvery(deleteTypePostAction, deleteTypePostSaga);
  yield takeEvery(editTypePostAction, editTypePostSaga);
  yield takeEvery(changePositionTypePostAction, changePositionTypePostSaga);
}
