import React from 'react';
import screen1 from 'resources/images/chatAndChannelPrompt.png';
import s from './index.module.scss';

export const ChatAndChannelPrompt = () => (
  <div className={s.container}>
    <h4 className={s.title}>Чаты и каналы</h4>
    <p className={s.text}>
      Все чаты и каналы сообщества, которые объединены в ваш тематический проект. Они будут доступны
      по кнопке Чаты и каналы в навигационном боте. Пример на скриншоте. Загрузите иконку, название,
      описание, ссылку формата https:// чата или канала.
    </p>

    <p className={s.text}>
      Первым добавьте ссылку на телеграм-канал, где будут публиковаться объявления, созданные
      пользователями через бот. Например название канала может быть: Маркет “Название сообщества”.
      Затем добавьте по порядку все остальные чаты и каналы, которые должны быть доступны вашим
      пользователям. Ссылки могут быть со сторонних ресурсов, сайтов, мессенджеров.
    </p>

    <div className={s.screen__container}>
      <img src={screen1} alt="screen" />
    </div>
  </div>
);
