import { all } from 'redux-saga/effects';
import { userSaga } from './userSaga';
import { categorySaga } from './categorySaga';
import { typePostSaga } from './typePostSaga';
import { channelSaga } from './channelSaga';
import { authSaga } from './authSaga';
import { navigatorSaga } from './navigatorSaga';
import { currencySaga } from './currencySaga';
import { botSaga } from './botSaga';
import { channelMiniSaga } from './channelMiniSaga';
import { questionSaga } from './questionSaga';
import { webSaga } from './webSaga';
import { postSaga } from './postSaga';
import { orderSaga } from './orderSaga';

export function* rootSaga() {
  yield all([
    userSaga(),
    categorySaga(),
    typePostSaga(),
    channelSaga(),
    authSaga(),
    navigatorSaga(),
    currencySaga(),
    botSaga(),
    channelMiniSaga(),
    questionSaga(),
    webSaga(),
    postSaga(),
    orderSaga()
  ]);
}
