import React from 'react';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { FormContainer } from 'components/form/formContainer';
import { clientMainValidationSchema } from 'utils/validation';
import { ClientCommunity } from 'components/prompts/clientCommunity';
import { useModal } from 'hooks/useModal';
import { modals } from 'components/modal';
import { editClientAction } from 'store/actions/authAction';
import { FormikText } from 'components/form/inputs/text';
import { PromptButton } from 'components/buttons/promptButton';
import { FormikPhone } from '../inputs/phone';

import s from './index.module.scss';

export const EditClientForm = () => {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.auth.clientId);
  const clientFullName = useSelector((state) => state.auth.clientFullName);
  const clientPhone = useSelector((state) => state.auth.clientPhone);
  const clientEmail = useSelector((state) => state.auth.clientEmail);
  const clientCommunityName = useSelector((state) => state.auth.clientCommunityName);
  const { showModal, hideModal } = useModal();

  const showModalPrompt = (content) => {
    showModal(modals.ModalPrompt, { showModal, hideModal, content });
  };

  const initialValues = {
    fullname: clientFullName,
    phone: clientPhone,
    email: clientEmail,
    communityName: clientCommunityName
  };

  const onSubmit = (values) => {
    const clientInfo = {
      email: values.email,
      phone: values.phone,
      fullname: values.fullname
    };

    dispatch(editClientAction({ clientInfo, clientId }));
  };

  return (
    <>
      <FormContainer
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={clientMainValidationSchema}
      >
        {(formik) => {
          const { errors } = formik;
          return (
            <>
              <div className={s.input__group}>
                <div className={s.input__container}>
                  <FormikText
                    name="fullname"
                    fullWidth
                    label="Имя"
                    error={!!errors.fullname}
                    helperText={errors.fullname}
                  />
                  {/* <PromptButton callbackHandler={() => showModalPrompt(<ClientName />)} /> */}
                </div>

                <div className={s.input__container}>
                  <FormikPhone
                    mask="+7 999 999 99 99"
                    name="phone"
                    fullWidth
                    label="Номер телефона"
                    error={!!errors.phone}
                    helperText={errors.phone}
                  />
                  {/* <PromptButton callbackHandler={() => showModalPrompt(<ClientName />)} /> */}
                </div>
                <div className={s.input__container}>
                  <FormikText
                    name="email"
                    fullWidth
                    label="Email"
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                  {/* <PromptButton callbackHandler={() => showModalPrompt(<ClientName />)} /> */}
                </div>

                <div className={s.input__container}>
                  <FormikText disabled name="communityName" fullWidth label="Название сообщества" />
                  <div className={s.prompt__button__wrapper}>
                    <PromptButton callbackHandler={() => showModalPrompt(<ClientCommunity />)} />
                  </div>
                </div>
              </div>
              <div className={s.button__group}>
                <Button variant="contained" className={s.button__save} type="submit" size="large">
                  сохранить
                </Button>
              </div>
            </>
          );
        }}
      </FormContainer>
    </>
  );
};
