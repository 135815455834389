import { createAction } from '@reduxjs/toolkit';

export const createBotAction = createAction('createBot', (botData) => ({
  payload: {
    botData
  }
}));

export const editBotAction = createAction('editBot', ({ botData, tokenId }) => ({
  payload: {
    botData,
    tokenId
  }
}));
