import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { setSearch } from 'store/slices/userFilterSlice';
import { FormContainer } from '../formContainer';
import { FormikText } from '../inputs/text';
import s from './index.module.scss';

export const UserFilterForm = () => {
  const dispatch = useDispatch();
  const search = useSelector((state) => state.postFilter.search);

  const changeSearch = (e) => {
    // console.log(e.target.value);
    dispatch(setSearch(e.target.value));
  };

  const initialValues = {
    search
  };

  return (
    <div className={s.container}>
      <FormContainer initialValues={initialValues}>
        {() => (
          <div className={s.heading}>
            <h1 className={s.title}>Пользователи</h1>
            <FormikText
              sx={{ backgroundColor: '#fff', width: 440 }}
              name="search"
              onChange={changeSearch}
              placeholder="Найти пользователя по имени, номеру телефона"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>
        )}
      </FormContainer>
    </div>
  );
};
