import React from 'react';
import { Button } from '@mui/material';
import { FormContainer } from 'components/form/formContainer';
import { editTypePostAction } from 'store/actions/typesPostActions';
import { typePostValidationSchema } from 'utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { FormikText } from 'components/form/inputs/text';
import s from './index.module.scss';

export const EditTypePostForm = ({ hideModal, rowData }) => {
  const dispatch = useDispatch();
  const botId = useSelector((state) => state.bot.tokenId);

  const initialValues = {
    title: rowData.title
  };

  const onSubmit = (values) => {
    const typePost = {
      id: rowData.id,
      title: values.title
    };

    dispatch(editTypePostAction({ botId, typePost }));
    hideModal();
  };

  return (
    <>
      <FormContainer
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={typePostValidationSchema}
      >
        {(formik) => {
          const { errors } = formik;
          return (
            <>
              <div className={s.input__container}>
                <FormikText
                  name="title"
                  fullWidth
                  label="Название"
                  error={!!errors.title}
                  helperText={errors.title}
                />
              </div>

              <div className={s.button__group}>
                <Button
                  variant="contained"
                  className={s.button__save}
                  type="submit"
                  size="large"
                  fullWidth
                >
                  сохранить
                </Button>
                <Button
                  onClick={hideModal}
                  fullWidth
                  variant="outlined"
                  className={s.button__reset}
                  color="secondary"
                  size="large"
                >
                  отменить
                </Button>
              </div>
            </>
          );
        }}
      </FormContainer>
    </>
  );
};
