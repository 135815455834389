import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeIcon from '@mui/icons-material/Mode';
import { deleteTypePostAction } from 'store/actions/typesPostActions';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { useModal } from 'hooks/useModal';
import { modals } from 'components/modal';
import s from './index.module.scss';

export const RowGroupButtons = ({ rowData }) => {
  const dispatch = useDispatch();
  const tokenId = useSelector((state) => state.bot.tokenId);
  const { showModal, hideModal } = useModal();

  const deleteTypePost = () => {
    if (rowData?.id) {
      dispatch(deleteTypePostAction({ botId: tokenId, id: rowData.id }));
    }
  };

  const showModalEditTypePost = () => {
    showModal(modals.ModalEditTypePost, { showModal, hideModal, rowData });
  };

  const showModalDeleteTypePost = () => {
    showModal(modals.ModalConfirm, {
      showModal,
      hideModal,
      question: 'Удалить тип поста?',
      confirmFunc: () => deleteTypePost()
    });
  };

  return (
    <div className={s.button__group}>
      <IconButton size="small" onClick={showModalEditTypePost}>
        <ModeIcon />
      </IconButton>
      <IconButton aria-label="delete" size="small" onClick={showModalDeleteTypePost}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
