/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  posts: [],
  total: 0,
  availableCitiesForPosts: [],
  availableUsersForPosts: [],
  postTitle: '',
  postCity: null,
  typePost: null,
  postDesc: '',
  postAddress: '',
  postPhotos: [],
  postPrice: null,
  postCurrency: null,
  postUserName: null,
  postCategory: null,
  postPublished: true,
  postOldFiles: [],
  postCreationStatus: ''
};

export const postSlice = createSlice({
  name: Slices.post,
  initialState,
  reducers: {
    setPosts: (state, action) => {
      state.posts = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setAvailableCitiesForPosts: (state, action) => {
      state.availableCitiesForPosts = action.payload;
    },
    setAvailableUsersForPosts: (state, action) => {
      state.availableUsersForPosts = action.payload;
    },
    setPostsTitle: (state, action) => {
      state.postTitle = action.payload;
    },
    setPostsCity: (state, action) => {
      state.postCity = action.payload;
    },
    setTypePost: (state, action) => {
      state.typePost = action.payload;
    },
    setPostsDesc: (state, action) => {
      state.postDesc = action.payload;
    },
    setPostAddress: (state, action) => {
      state.postAddress = action.payload;
    },
    setPostsPhotos: (state, action) => {
      state.postPhotos = action.payload;
    },
    setPostsOldFiles: (state, action) => {
      state.postOldFiles = action.payload;
    },

    setPostsPrice: (state, action) => {
      state.postPrice = action.payload;
    },
    setPostsCurrency: (state, action) => {
      state.postCurrency = action.payload;
    },
    setPostsUserName: (state, action) => {
      state.postUserName = action.payload;
    },
    setPostsCategory: (state, action) => {
      state.postCategory = action.payload;
    },
    setPostsPublished: (state, action) => {
      state.postPublished = action.payload;
    },
    setPostCreationStatus: (state, action) => {
      state.postCreationStatus = action.payload;
    }
  }
});

export const {
  setPosts,
  setTotal,
  setAvailableCitiesForPosts,
  setAvailableUsersForPosts,
  setPostsTitle,
  setPostsCity,
  setTypePost,
  setPostsDesc,
  setPostAddress,
  setPostsPhotos,
  setPostsOldFiles,
  setPostsPrice,
  setPostsCurrency,
  setPostsUserName,
  setPostsCategory,
  setPostsPublished,
  setPostCreationStatus
} = postSlice.actions;

export default postSlice.reducer;
