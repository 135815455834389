/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  changePositionQuestionAction,
  deleteQuestionAction,
  getQuestionsAction
} from 'store/actions/questionsActions';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableBody,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { modals } from 'components/modal';
import { useModal } from 'hooks/useModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeIcon from '@mui/icons-material/Mode';
import { PromptButton } from 'components/buttons/promptButton';
import { QuestionPrompt } from 'components/prompts/questionPrompt';
import { reorder } from 'utils/reorder';
import { Activator } from './activator';
import s from './index.module.scss';

export const Questions = () => {
  const dispatch = useDispatch();
  const navId = useSelector((state) => state.navigator.navigatorInfo?.token);
  const questions = useSelector((state) => state.question.questions);
  const [myQuestions, setMyQuestions] = useState([...questions]);
  const { showModal, hideModal } = useModal();

  useEffect(() => {
    // превентивное изменение позиции
    const isExistsPositionNull = questions.find(
      (item) => item?.position === null || item?.position === undefined
    );
    const initialPosition = questions.map((question, ind) => ({
      questionId: question.id,
      position: ind
    }));

    const newOrder = {
      questions: [...initialPosition]
    };

    if (isExistsPositionNull) dispatch(changePositionQuestionAction({ navId, newOrder }));
  }, [questions]);

  useEffect(() => {
    dispatch(getQuestionsAction(navId));
  }, [navId]);

  useEffect(() => {
    setMyQuestions([...questions]);
  }, [questions]);

  const deleteQuestion = (row) => {
    dispatch(deleteQuestionAction(row.id));
  };

  const showModalCreateQuestion = () => {
    showModal(modals.ModalCreateQuestion, { showModal, hideModal });
  };

  const showModalPrompt = (content) => {
    showModal(modals.ModalPrompt, { showModal, hideModal, content });
  };

  const showModalDeleteQuestion = (row) => {
    showModal(modals.ModalConfirm, {
      showModal,
      hideModal,
      question: 'Удалить вопрос?',
      confirmFunc: () => deleteQuestion(row)
    });
  };

  const showModalEditQuestion = (row) => {
    showModal(modals.ModalEditQuestion, { showModal, hideModal, rowData: row });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const changedMyQuestions = reorder(myQuestions, result.source.index, result.destination.index);

    const newPosition = changedMyQuestions.map((question, ind) => ({
      questionId: question.id,
      position: ind
    }));
    const newOrder = {
      questions: [...newPosition]
    };

    dispatch(changePositionQuestionAction({ navId, newOrder }));

    setMyQuestions([...changedMyQuestions]);
  };

  return (
    <div className={s.container}>
      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table sx={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className={s.heading__box}>
                  <p>Текст вопроса</p>
                  <PromptButton callbackHandler={() => showModalPrompt(<QuestionPrompt />)} />
                </div>
              </TableCell>
              <TableCell align="right">
                <Button
                  onClick={showModalCreateQuestion}
                  endIcon={<AddIcon style={{ fontSize: '16px' }} />}
                  className={s.button__add}
                  variant="contained"
                >
                  Добавить
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(provided) => {
                return (
                  <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                    {myQuestions.length > 0 &&
                      myQuestions.map((row, index) => (
                        <Draggable key={row.id} draggableId={row.id} index={index}>
                          {(provided, snapshot) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                background: snapshot.isDragging ? 'rgba(0,0,0, 0.3)' : 'none'
                              }}
                            >
                              <TableCell width="290" {...provided.dragHandleProps}>
                                <p className={!row.active ? s.text__inactive : ''}>
                                  {row.text}&nbsp;{row.required && '*'}&nbsp;
                                </p>
                              </TableCell>
                              <TableCell align="right" width="290">
                                <div className={s.button__group}>
                                  {!row.required ? (
                                    <>
                                      <IconButton
                                        size="small"
                                        onClick={() => showModalEditQuestion(row)}
                                      >
                                        <ModeIcon />
                                      </IconButton>
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                        onClick={() => showModalDeleteQuestion(row)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </>
                                  ) : (
                                    <Activator row={row} />
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </TableBody>
                );
              }}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </div>
  );
};
