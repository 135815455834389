import React from 'react';
import screen1 from 'resources/images/subdomenPromptScreen.png';
import s from './index.module.scss';

export const SubdomenPrompt = () => (
  <div className={s.container}>
    <h4 className={s.title}>Поддомен для web</h4>
    <p className={s.text}>
      Укажите название, которое будет являться частью доменного имени web-сайта. Поддомен
      отображается в адресной строке и является URL web-сайта.
    </p>

    <div className={s.screens}>
      <div className={s.screen__container}>
        <img src={screen1} alt="screen" />
      </div>
    </div>
  </div>
);
