import axios from 'axios';
import { apiUrl } from 'constant/data';

class API {
  constructor() {
    this.url = apiUrl;
  }

  handleSuccess = (response) => response;

  handleError = (error) => {
    switch (error.response.status) {
      case 401:
        console.log('error 401 => ', error);
        // window.location.href = SERVICES_URLS.auth;
        break;

      default:
        break;
    }
    return Promise.reject(error);
  };

  create = (headers) => {
    const service = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Origin': `${window.location.protocol}//${window.location.host}`,
        ...headers
      }
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    return service;
  };

  get = (path = '', params, headers) => {
    const service = this.create(headers);
    return service.request({
      method: 'GET',
      url: `${this.url}${path}`,
      params
    });
  };

  post = (path = '', data = {}, headers) => {
    const service = this.create(headers);
    return service.request({
      method: 'POST',
      url: `${this.url}${path}`,
      data
    });
  };

  put = (path = '', data = {}, headers) => {
    const service = this.create(headers);
    return service.request({
      method: 'PUT',
      url: `${this.url}${path}`,
      data
    });
  };

  delete = (path = '', data = {}, headers) => {
    const service = this.create(headers);
    return service.request({
      method: 'DELETE',
      url: `${this.url}${path}`,
      data
    });
  };
}

export default new API();
