/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { editChannelValidationSchema } from 'utils/validation';
import { editChannelAction } from 'store/actions/channelsActions';
import { apiUrl } from 'constant/data';
import { Button } from '@mui/material';
import { DownloadSVG } from 'resources/svg';
import { FormContainer } from 'components/form/formContainer';
import { useSelector, useDispatch } from 'react-redux';
import { FormikFile } from '../inputs/field';
import { PreviewPhoto } from '../previewPhoto';
import { FormikText } from '../inputs/text';
import s from './index.module.scss';

export const EditChannelForm = ({ channelData, hideModal }) => {
  // console.log('EditChannelForm', channelData);
  const dispatch = useDispatch();
  const navigatorInfo = useSelector((state) => state.navigator.navigatorInfo);
  const navigatorId = navigatorInfo.token;
  const navLinkId = channelData.id;

  const [file, setFile] = useState('');
  const selectFile = (path) => setFile(path);

  const initialValues = {
    title: channelData.title,
    description: channelData.description,
    link: channelData.link,
    image: ''
  };

  const onSubmit = (values) => {
    const formData = new FormData();
    if (file) {
      formData.append('image', file);
    }

    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('link', values.link);
    formData.append('navId', navigatorId);

    dispatch(editChannelAction({ body: formData, navLinkId }));
    hideModal();
  };

  return (
    <>
      <FormContainer
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={editChannelValidationSchema}
      >
        {(formik) => {
          const { errors } = formik;
          return (
            <div className={s.form__container}>
              <div className={s.form__left}>
                {file || !channelData.image ? (
                  <PreviewPhoto file={file} />
                ) : (
                  <div className={s.image__container}>
                    <img src={channelData.image} alt="preview" />
                  </div>
                )}
                <label className={s.input__wrapper__file}>
                  <FormikFile
                    hidden
                    selectFile={selectFile}
                    name="image"
                    id="image"
                    accept=".png, .jpg, .jpeg"
                  />
                  <Button
                    className={s.button__upload}
                    component="span"
                    endIcon={<DownloadSVG />}
                    fullWidth
                    variant="text"
                  >
                    Изменить картинку
                  </Button>
                </label>
              </div>

              <div className={s.form__right}>
                <div className={s.input__wrapper}>
                  <FormikText
                    helperText={errors.title ? errors.title : 'До 30 символов '}
                    error={!!errors.title}
                    name="title"
                    fullWidth
                    label="Название"
                    inputProps={{
                      maxLength: 30
                    }}
                  />
                </div>
                <div className={s.input__wrapper}>
                  <FormikText
                    multiline
                    minRows={4}
                    helperText={errors.description ? errors.description : 'До 255 символов'}
                    error={!!errors.description}
                    name="description"
                    fullWidth
                    label="Описание"
                    inputProps={{
                      maxLength: 255
                    }}
                  />
                </div>
                <div className={s.input__wrapper}>
                  <FormikText
                    name="link"
                    fullWidth
                    label="Ссылка"
                    error={!!errors.link}
                    helperText={errors.link}
                  />
                </div>

                <div className={s.button__group}>
                  <Button variant="contained" className={s.button__save} type="submit">
                    сохранить
                  </Button>
                  <Button
                    variant="outlined"
                    className={s.button__reset}
                    color="secondary"
                    onClick={hideModal}
                  >
                    отмена
                  </Button>
                </div>
              </div>
            </div>
          );
        }}
      </FormContainer>
    </>
  );
};
