import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from 'components/sidebar';
import { Header } from 'components/header';
import s from './index.module.scss';

export const MainLayout = ({ children, route }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
  const toggleSidebar = () => setSidebarIsOpen(!sidebarIsOpen);

  return (
    <>
      <Header toggleSidebar={toggleSidebar} />
      <Outlet />
      <div className={s.page}>
        <Sidebar sidebarIsOpen={sidebarIsOpen} />
        <div className={s.page__wrapper}>
          <div className={s.page__content}>{children || null}</div>
        </div>
      </div>
    </>
  );
};
