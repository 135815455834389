import { createAction } from '@reduxjs/toolkit';

export const getPostsAction = createAction('getPosts', ({ botId, queryParams }) => ({
  payload: {
    botId,
    queryParams
  }
}));

export const getOnePostAction = createAction('getOnePost', (id) => ({
  payload: {
    id
  }
}));

export const editOnePostAction = createAction('editOnePost', ({ id, body }) => ({
  payload: {
    id,
    body
  }
}));

export const createPostAction = createAction('createPost', (body) => ({
  payload: {
    body
  }
}));

export const getAvailableCitiesForPostAction = createAction(
  'getAvailableCitiesForPost',
  (filterStr) => ({
    payload: {
      filterStr
    }
  })
);

export const getAvailableUsersForPostAction = createAction(
  'getAvailableUsersForPost',
  ({ botId, filterStr }) => ({
    payload: {
      botId,
      filterStr
    }
  })
);

export const raisePostAction = createAction('raisePost', (id) => ({
  payload: {
    id
  }
}));

export const publishPostAction = createAction('publishPost', ({ id, body }) => ({
  payload: {
    id,
    body
  }
}));
