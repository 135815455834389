import SERVICE_API from 'api';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createBotAction, editBotAction } from 'store/actions/botActions';
import {
  setBotLink,
  setBotTitle,
  setBotTokenId,
  setIsBotExists,
  setBotLoading,
  setBotError
} from 'store/slices/botSlice';
import { setNotification } from 'store/slices/notificationsSlice';

function* createBotSaga(action) {
  try {
    yield put(setBotLoading(true));
    yield put(setBotError(''));
    const response = yield call(() => SERVICE_API.BotAPI.createBot(action.payload.botData));

    if (response.data) {
      yield put(setIsBotExists(true));
      yield put(setBotTokenId(response.data.token));
      yield put(setBotLink(response.data.link));
      yield put(setBotTitle(response.data.title));
      yield put(
        setNotification({
          message: 'Настройки сохранены',
          options: {
            variant: 'success'
          }
        })
      );
    }
  } catch (error) {
    console.log(error);

    yield put(setBotError(error?.response?.data?.error));
  } finally {
    yield put(setBotLoading(false));
  }
}

function* editBotSaga(action) {
  try {
    yield put(setBotError(''));
    const response = yield call(() => SERVICE_API.BotAPI.editBot(action.payload));
    if (response.data) {
      yield put(setBotTokenId(response.data.token));
      yield put(setBotLink(response.data.link));
      yield put(setBotTitle(response.data.title));
      yield put(
        setNotification({
          message: 'Настройки сохранены',
          options: {
            variant: 'success'
          }
        })
      );
    }
  } catch (error) {
    console.log(error);

    yield put(setBotError(error?.response?.data?.error));
  }
}

export function* botSaga() {
  yield takeEvery(createBotAction, createBotSaga);
  yield takeEvery(editBotAction, editBotSaga);
}
