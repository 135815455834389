import React from 'react';
import { Button, Paper } from '@mui/material';
import { catalogMainValidationSchema } from 'utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { createBotAction } from 'store/actions/botActions';
import { FormContainer } from 'components/form/formContainer';
import { FormikText } from 'components/form/inputs/text';
import { useModal } from 'hooks/useModal';
import { modals } from 'components/modal';
import { PromptButton } from 'components/buttons/promptButton';
import { BotTokenPrompt } from 'components/prompts/botTokenPrompt';
import s from './index.module.scss';

export const CatalogCreateBotForm = () => {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.auth.clientId);
  const botError = useSelector((state) => state.bot.botError);
  const tokenError = botError === 'Bot token invalid' ? 'Невалидный токен' : '';
  const { showModal, hideModal } = useModal();

  const showModalPrompt = (content) => {
    showModal(modals.ModalPrompt, { showModal, hideModal, content });
  };

  const initialValues = {
    token: ''
  };

  const onSubmit = (values) => {
    const botData = {
      token: values.token,
      clientId,
      categories: [],
      typesPost: [],
      currencies: []
    };

    dispatch(createBotAction(botData));
  };

  return (
    <div className={s.container}>
      <Paper elevation={0} className={s.paper}>
        <FormContainer
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
          validationSchema={catalogMainValidationSchema}
        >
          {(formik) => {
            const { errors } = formik;
            return (
              <>
                <div className={s.input__container}>
                  <FormikText
                    name="token"
                    fullWidth
                    label="Токен бота"
                    error={!!errors.token || !!tokenError}
                    helperText={errors.token || tokenError}
                  />
                  <PromptButton callbackHandler={() => showModalPrompt(<BotTokenPrompt />)} />
                </div>

                <div className={s.button__group}>
                  <Button variant="contained" className={s.button__save} type="submit" size="large">
                    сохранить
                  </Button>
                </div>
              </>
            );
          }}
        </FormContainer>
      </Paper>
    </div>
  );
};
