import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import RemoveIcon from '@mui/icons-material/Remove';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import { Button, InputAdornment } from '@mui/material';
import { getCategoriesAction } from 'store/actions/categoriesActions';
import {
  getAvailableCitiesForPostAction,
  getAvailableUsersForPostAction
} from 'store/actions/postActions';
import {
  setCategoriesId,
  setCategoriesName,
  setCity,
  setAuthor,
  resetFilters,
  setMinPrice,
  setMaxPrice,
  setSearch
} from 'store/slices/postFilterSlice';
import { checkIsFilterEmpty } from 'utils/check';
import { FormContainer } from '../formContainer';
import { FormikAutocomplete } from '../inputs/autocomplete';
import { FormikSelect } from '../inputs/select';
import { FormikText } from '../inputs/text';
import s from './index.module.scss';

export const AdsFilterForm = () => {
  const dispatch = useDispatch();
  const botId = useSelector((state) => state.bot.tokenId);
  const category = useSelector((state) => state.category.categories);
  const categoriesId = useSelector((state) => state.postFilter.categoriesId);
  const categoriesName = useSelector((state) => state.postFilter.categoriesName);
  const availableCitiesForPosts = useSelector((state) => state.post.availableCitiesForPosts);
  const availableUsersForPosts = useSelector((state) => state.post.availableUsersForPosts);
  const city = useSelector((state) => state.postFilter.city);
  const author = useSelector((state) => state.postFilter.author);
  const minPrice = useSelector((state) => state.postFilter.minPrice);
  const maxPrice = useSelector((state) => state.postFilter.maxPrice);
  const search = useSelector((state) => state.postFilter.search);
  const isFilterEmpty = checkIsFilterEmpty(
    categoriesId,
    categoriesName,
    city,
    author,
    minPrice,
    maxPrice,
    search
  );

  useEffect(() => {
    dispatch(getCategoriesAction(botId));
  }, [dispatch, botId]);

  const changeCategory = (event) => {
    const categoryId = event?.target?.value;
    const selectedCategory = category.find((item) => item.id === categoryId);

    dispatch(setCategoriesId(categoryId));
    dispatch(setCategoriesName(selectedCategory.title));
  };

  const changeCity = (newVal) => {
    dispatch(setCity(newVal));
  };

  const changeAuthor = (newVal) => {
    dispatch(setAuthor(newVal));
  };

  const changeMinPrice = (e) => {
    dispatch(setMinPrice(e.target.value));
  };

  const changeMaxPrice = (e) => {
    dispatch(setMaxPrice(e.target.value));
  };

  const changeSearch = (e) => {
    dispatch(setSearch(e.target.value));
  };

  const getAvailableCitiesForPost = (debouncedSearchValue) => {
    dispatch(getAvailableCitiesForPostAction(debouncedSearchValue));
  };

  const getAvailableUsersForPost = (debouncedSearchValue) => {
    dispatch(getAvailableUsersForPostAction({ botId, filterStr: debouncedSearchValue }));
  };

  const initialValues = {
    search,
    selectedCategoryId: categoriesId,
    selectedCity: city,
    selectedUser: author,
    minPrice,
    maxPrice
  };
  // ----------------------------------------------------------
  const handleResetCategory = (setFieldValue) => {
    dispatch(setCategoriesId(null));
    dispatch(setCategoriesName(null));
    setFieldValue('selectedCategoryId', '');
  };

  const handleResetCity = (setFieldValue) => {
    dispatch(setCity(null));
    setFieldValue('selectedCity', '');
  };

  const handleResetAuthor = (setFieldValue) => {
    dispatch(setAuthor(null));
    setFieldValue('selectedUser', '');
  };

  const handleResetFilters = (resetForm) => {
    resetForm({ values: {} });
    dispatch(resetFilters());
  };

  return (
    <div className={s.container}>
      <FormContainer initialValues={initialValues}>
        {(formik) => {
          const { setFieldValue, resetForm } = formik;

          return (
            <div>
              <div className={s.heading}>
                <h1 className={s.title}>Объявления</h1>
                <FormikText
                  sx={{ backgroundColor: '#fff', width: 440 }}
                  name="search"
                  onChange={changeSearch}
                  placeholder="Найти по названию"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>

              <h4 className={s.title}>Фильтры</h4>
              <div className={s.filter__row}>
                <div className={s.filter__row__left}>
                  <FormikAutocomplete
                    name="selectedUser"
                    onChange={changeAuthor}
                    label="Автор"
                    getOptionsCallback={getAvailableUsersForPost}
                    options={availableUsersForPosts.map((item) => ({
                      ...item,
                      username: `@${item.username}`
                    }))}
                    optionName="username"
                  />

                  <FormikAutocomplete
                    name="selectedCity"
                    onChange={changeCity}
                    label="Город"
                    getOptionsCallback={getAvailableCitiesForPost}
                    options={availableCitiesForPosts}
                    optionName="title"
                  />

                  <FormikSelect
                    options={category}
                    name="selectedCategoryId"
                    onChange={changeCategory}
                    label="Категория"
                  />
                </div>

                <div className={s.filter__row__right}>
                  <FormikText
                    sx={{ backgroundColor: '#fff', width: 165 }}
                    onChange={changeMinPrice}
                    type="number"
                    name="minPrice"
                    label="От"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CurrencyRubleIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  <RemoveIcon />
                  <FormikText
                    sx={{ backgroundColor: '#fff', width: 165 }}
                    onChange={changeMaxPrice}
                    type="number"
                    name="maxPrice"
                    label="До"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CurrencyRubleIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </div>

              <div className={s.reset__row}>
                {author && (
                  <Chip label={author.username} onDelete={() => handleResetAuthor(setFieldValue)} />
                )}

                {categoriesName && (
                  <Chip
                    label={categoriesName}
                    onDelete={() => handleResetCategory(setFieldValue)}
                  />
                )}

                {city && (
                  <Chip label={city.title} onDelete={() => handleResetCity(setFieldValue)} />
                )}

                {isFilterEmpty && (
                  <Button
                    className={s.button__reset}
                    startIcon={<CloseIcon />}
                    variant="text"
                    color="secondary"
                    type="reset"
                    onClick={() => handleResetFilters(resetForm)}
                    // eslint-disable-next-line
                    // onClick={handleResetFilters.bind(null, resetForm)}
                  >
                    Очистить фильтры
                  </Button>
                )}
              </div>
            </div>
          );
        }}
      </FormContainer>
    </div>
  );
};
