import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { CatalogEditBotForm } from 'components/form/catalogEditBotForm';
import { CatalogCategory } from 'components/settings/catalogCategory';
import { CatalogTypes } from 'components/settings/catalogTypes';
import { useSelector } from 'react-redux';
import { CatalogCreateBotForm } from 'components/form/catalogCreateBotForm';
import { CatalogCurrency } from '../catalogCurrency';
import { CatalogPublication } from '../catalogPublication';
import s from './index.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className={s.tabpanel__container}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const CatalogContent = () => {
  const isBotExists = useSelector((state) => state.bot.isBotExists);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="общие настройки" {...a11yProps(0)} />
          <Tab label="категории " {...a11yProps(1)} disabled={!isBotExists} />
          <Tab label="типы объявления" {...a11yProps(2)} disabled={!isBotExists} />
          <Tab label="валюты" {...a11yProps(3)} disabled={!isBotExists} />
          <Tab label="настройка публикаций" {...a11yProps(4)} disabled={!isBotExists} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {isBotExists ? <CatalogEditBotForm /> : <CatalogCreateBotForm />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CatalogCategory />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CatalogTypes />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CatalogCurrency />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CatalogPublication />
      </TabPanel>
    </Box>
  );
};
