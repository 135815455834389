import React from 'react';
import { Button, Chip, InputAdornment } from '@mui/material';
import { checkIsFilterEmpty } from 'utils/check';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { resetFilters, setBuyer, setSearch, setSeller } from 'store/slices/orderFilterSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAvailableBuyersForOrderAction,
  getAvailableSellersForOrderAction
} from 'store/actions/orderActions';
import { FormContainer } from '../formContainer';
import { FormikText } from '../inputs/text';
import { FormikAutocomplete } from '../inputs/autocomplete';
import s from './index.module.scss';

export const OrdersFilterForm = () => {
  const dispatch = useDispatch();
  const botId = useSelector((state) => state.bot.tokenId);
  const availableSellersForOrder = useSelector((state) => state.order.availableSellersForOrder);
  const availableBuyersForOrder = useSelector((state) => state.order.availableBuyersForOrder);
  const seller = useSelector((state) => state.orderFilter.seller);
  const buyer = useSelector((state) => state.orderFilter.buyer);
  const search = useSelector((state) => state.orderFilter.search);
  const isFilterEmpty = checkIsFilterEmpty(seller, buyer, search);

  const getAvailableSellersForOrder = (debouncedSearchValue) => {
    dispatch(getAvailableSellersForOrderAction({ botId, filterStr: debouncedSearchValue }));
  };

  const getAvailableBuyersForOrder = (debouncedSearchValue) => {
    dispatch(getAvailableBuyersForOrderAction({ botId, filterStr: debouncedSearchValue }));
  };

  const changeSearch = (e) => {
    dispatch(setSearch(e.target.value));
  };

  const changeSeller = (newVal) => {
    dispatch(setSeller(newVal));
  };

  const changeBuyer = (newVal) => {
    dispatch(setBuyer(newVal));
  };

  const handleResetSeller = (setFieldValue) => {
    dispatch(setSeller(null));
    setFieldValue('selectedSeller', '');
  };

  const handleResetBuyer = (setFieldValue) => {
    dispatch(setBuyer(null));
    setFieldValue('selectedBuyer', '');
  };

  const handleResetFilters = (resetForm) => {
    resetForm({ values: {} });
    dispatch(resetFilters());
  };

  const initialValues = {
    search,
    selectedSeller: seller,
    selectedBuyer: buyer
  };

  const onSubmit = (values) => {
    console.log('values ', values);
  };

  return (
    <div className={s.container}>
      <FormContainer initialValues={initialValues} onSubmit={onSubmit}>
        {(formik) => {
          const { setFieldValue, resetForm } = formik;

          return (
            <div>
              <div className={s.heading}>
                <h1 className={s.title}>Заказы</h1>
                <FormikText
                  sx={{ backgroundColor: '#fff', width: 440 }}
                  name="search"
                  onChange={changeSearch}
                  placeholder="Найти по названию"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>

              <h4 className={s.title}>Фильтры</h4>
              <div className={s.filter__row}>
                <div className={s.filter__row__left}>
                  <FormikAutocomplete
                    name="selectedSeller"
                    onChange={changeSeller}
                    label="Продавец"
                    getOptionsCallback={getAvailableSellersForOrder}
                    options={availableSellersForOrder.map((item) => ({
                      ...item,
                      username: `@${item.username}`
                    }))}
                    optionName="username"
                  />

                  <FormikAutocomplete
                    name="selectedBuyer"
                    onChange={changeBuyer}
                    label="Покупатель"
                    getOptionsCallback={getAvailableBuyersForOrder}
                    options={availableBuyersForOrder.map((item) => ({
                      ...item,
                      username: `@${item.username}`
                    }))}
                    optionName="username"
                  />
                </div>
              </div>

              <div className={s.reset__row}>
                {seller && (
                  <Chip
                    label={seller?.username}
                    onDelete={() => handleResetSeller(setFieldValue)}
                  />
                )}

                {buyer && (
                  <Chip label={buyer?.username} onDelete={() => handleResetBuyer(setFieldValue)} />
                )}

                {isFilterEmpty && (
                  <Button
                    className={s.button__reset}
                    startIcon={<CloseIcon />}
                    variant="text"
                    color="secondary"
                    type="reset"
                    onClick={() => handleResetFilters(resetForm)}
                  >
                    Очистить фильтры
                  </Button>
                )}
              </div>

              {/* <Button type="submit">test</Button> */}
            </div>
          );
        }}
      </FormContainer>
    </div>
  );
};
