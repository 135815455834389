import React from 'react';
import screen1 from 'resources/images/questionScreen.png';
import s from './index.module.scss';

export const QuestionPrompt = () => (
  <div className={s.container}>
    <h4 className={s.title}>Текст вопроса </h4>
    <p className={s.text}>
      Необходимые вам вопросы для сбора данных о пользователях или предоставлении доступа к закрытым
      чатам и каналам. Вопросы будут отображаться по порядку при запуске навигационного бота. Пример
      на скриншоте.
    </p>

    <div className={s.screen__container}>
      <img src={screen1} alt="screen" />
    </div>
  </div>
);
