import React from 'react';
import screen1 from 'resources/images/channelMiniPromptScreen1.png';
import s from './index.module.scss';

export const ChannelMiniPrompt = () => (
  <div className={s.container}>
    <h4 className={s.title}>Ссылка на канал</h4>
    <p className={s.text}>
      Укажите телеграм-каналы и чаты в которых бот будет публиковать объявления. Каналов может быть
      несколько. Первым каналом по списку обязательно поставьте telegram-канал, в котором должны
      публиковаться объявления. Например название канала может быть: Маркет “Название сообщества”.
      Ссылка на канал обязательна должна быть в формате @name. Ссылку можно взять из настроек
      каждого телеграм-канала. Название канала указано после символа слеш /
    </p>

    <div className={s.screens}>
      <div className={s.screen__container}>
        <img src={screen1} alt="screen" />
      </div>
    </div>
  </div>
);
