import React from 'react';
import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import s from './index.module.scss';

export const ImageAddButton = ({ handleUploadPhoto }) => (
  <div className={s.img__wrapper}>
    <IconButton aria-label="delete" size="large" sx={{ marginBottom: '0.5rem' }} component="label">
      <AddIcon sx={{ fontSize: '2rem' }} />
      <input hidden multiple type="file" accept=".png, .jpg, .jpeg" onChange={handleUploadPhoto} />
    </IconButton>

    <Button
      component="label"
      sx={{
        backgroundColor: 'rgba(0, 0, 0,0.3)',
        color: '#000',
        fontSize: '10px',
        borderRadius: '14px',
        '&:hover': { backgroundColor: 'rgba(0, 0, 0,0.2)' }
      }}
    >
      Добавить фото
      <input hidden multiple type="file" accept=".png, .jpg, .jpeg" onChange={handleUploadPhoto} />
    </Button>
  </div>
);
