/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */

import { Button, Paper } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { DownloadSVG } from 'resources/svg';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  createWebDataAction,
  deleteWebLogoAction,
  editWebDataAction,
  getWebDataAction
} from 'store/actions/webActions';
import clsx from 'clsx';
import { modals } from 'components/modal';
import { useModal } from 'hooks/useModal';
import { FormikText } from 'components/form/inputs/text';
import { createWebValidationSchema } from 'utils/validation';
import { LogoPrompt } from 'components/prompts/logoPrompt';
import { apiUrl } from 'constant/data';
import { PromptButton } from 'components/buttons/promptButton';
import { SubdomenPrompt } from 'components/prompts/subdomenPrompt';
import { FormContainer } from '../formContainer';
import { FormikFile } from '../inputs/field';
import { PreviewPhoto } from '../previewPhoto';
import s from './index.module.scss';

export const CreateWebForm = () => {
  const [file, setFile] = useState('');
  const selectFile = (path) => setFile(path);
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.auth.clientId);
  const webId = useSelector((state) => state.web.webId);
  const logo = useSelector((state) => state.web.logo);
  const subdomenSaved = useSelector((state) => state.web.subdomen);
  const isWebExists = useSelector((state) => state.web.isWebExists);
  const { showModal, hideModal } = useModal();

  const showModalPrompt = (content) => {
    showModal(modals.ModalPrompt, { showModal, hideModal, content });
  };

  useEffect(() => {
    dispatch(getWebDataAction(clientId));
  }, [clientId]);

  const initialValues = {
    image: '',
    subdomen: subdomenSaved
  };

  const onSubmit = (values) => {
    const formData = new FormData();

    if (!isWebExists) {
      formData.append('clientId', clientId);
    }

    if (file) {
      formData.append('logo', file);
    }

    formData.append('subdomen', values.subdomen);

    if (isWebExists) {
      dispatch(editWebDataAction({ id: webId, body: formData }));
    } else {
      dispatch(createWebDataAction({ clientId, body: formData }));
    }
  };

  const removeLogo = () => {
    dispatch(deleteWebLogoAction(webId));
  };

  return (
    <Paper elevation={0} className={s.container}>
      <FormContainer
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
        validationSchema={!isWebExists && createWebValidationSchema}
      >
        {(formik) => {
          const { errors } = formik;
          return (
            <div>
              {logo && (
                <Button
                  variant="text"
                  className={s.button__delete}
                  type="button"
                  endIcon={<DeleteIcon />}
                  onClick={removeLogo}
                >
                  удалить логотип
                </Button>
              )}

              {file ? (
                <PreviewPhoto file={file} customClassName={s.preview} />
              ) : logo ? (
                <div className={s.preview}>
                  <img src={`${apiUrl}/api/${logo}`} alt="" />
                </div>
              ) : (
                <PreviewPhoto
                  customClassName={clsx(s.preview, s.preview__empty)}
                  // customNoPhoto={noPhoto2}
                  customNoPhoto="https://imgholder.ru/250x100/8493a8/adb9ca&text=250x100&font=kelson"
                />
              )}

              <div className={s.file}>
                <label className={s.input__wrapper__file}>
                  <FormikFile hidden selectFile={selectFile} name="image" id="image" accept=".png, .jpg, .jpeg"/>
                  <Button
                    className={s.button__upload}
                    component="span"
                    endIcon={<DownloadSVG />}
                    variant="text"
                  >
                    {logo ? 'Изменить логотип' : 'Загрузить логотип'}
                  </Button>
                </label>
                <div className={s.prompt__button__wrapper}>
                  <PromptButton callbackHandler={() => showModalPrompt(<LogoPrompt />)} />
                </div>
              </div>

              <div className={s.input__container}>
                {subdomenSaved ? (
                  <FormikText name="subdomen" fullWidth label="Поддомен для Web" disabled />
                ) : (
                  <FormikText
                    name="subdomen"
                    fullWidth
                    label="Поддомен для Web"
                    error={!!errors.subdomen}
                    helperText={errors.subdomen}
                  />
                )}

                <div className={s.prompt__button__wrapper}>
                  <PromptButton callbackHandler={() => showModalPrompt(<SubdomenPrompt />)} />
                </div>
              </div>

              <div>
                <Button variant="contained" className={s.button__save} type="submit" size="large">
                  сохранить
                </Button>
              </div>
            </div>
          );
        }}
      </FormContainer>
    </Paper>
  );
};
