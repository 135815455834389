export const convertDate = (serverDate) => {
  if (!serverDate) return '';

  const dateStr = serverDate.slice(0, 10);
  const result = dateStr.split('-').reverse().join('.');

  return result;
};

export const convertPostFilterParams = (
  page,
  quantity,
  categoriesId,
  city,
  author,
  minPrice,
  maxPrice,
  search,
  curSortOption
) => {
  let queryParams = '';

  queryParams += quantity ? `quantity=${quantity}` : '';
  queryParams += page ? `&page=${page}` : '';
  queryParams += categoriesId ? `&categoriesId=${categoriesId}` : '';
  queryParams += city?.title ? `&cities=${city.title}` : '';
  queryParams += author?.id ? `&usersId=${author.id}` : '';
  queryParams += minPrice ? `&minPrice=${minPrice}` : '';
  queryParams += maxPrice ? `&maxPrice=${maxPrice}` : '';
  queryParams += search ? `&text=${search}` : '';
  queryParams += curSortOption?.value ? `&sortId=${curSortOption?.value}` : '';

  return queryParams;
};

export const convertUserFilterParams = (page, quantity, search) => {
  let queryParams = '';

  queryParams += quantity ? `quantity=${quantity}` : '';
  queryParams += page ? `&page=${page}` : '';
  queryParams += search ? `&text=${search}` : '';
  return queryParams;
};

export const convertOrderFilterParams = (page, quantity, search, seller, buyer, curSortOption) => {
  let queryParams = '';

  queryParams += quantity ? `quantity=${quantity}` : '';
  queryParams += page ? `&page=${page}` : '';
  queryParams += search ? `&text=${search}` : '';
  queryParams += seller?.id ? `&sellerId=${seller.id}` : '';
  queryParams += buyer?.id ? `&buyerId=${buyer.id}` : '';
  queryParams += curSortOption?.value ? `&sortId=${curSortOption?.value}` : '';
  return queryParams;
};
