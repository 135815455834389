import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MainRouter } from 'components/router';
import { loginAction } from 'store/actions/authAction';
import { Notifier } from 'components/notifier';

function App() {
  const dispatch = useDispatch();
  const authCredential = JSON.parse(localStorage.getItem('authCredential'));

  useEffect(() => {
    if (authCredential) {
      dispatch(loginAction(authCredential));
    }
  }, [authCredential]);

  return (
    <>
      <MainRouter />
      <Notifier />
    </>
  );
}

export default App;
