/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  questions: []
};

export const questionSlice = createSlice({
  name: Slices.question,
  initialState,
  reducers: {
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },

    addQuestion: (state, action) => {
      state.questions.push(action.payload);
    },

    deleteQuestion: (state, action) => {
      state.questions = state.questions.filter((item) => item.id !== action.payload.deletedId);
    },

    editQuestion: (state, action) => {
      const { changedQuestion } = action.payload;

      state.questions = state.questions.map((item) => {
        if (item.id === changedQuestion.id) {
          return changedQuestion;
        }
        return item;
      });
    }
  }
});

export const { setQuestions, addQuestion, deleteQuestion, editQuestion } = questionSlice.actions;

export default questionSlice.reducer;
