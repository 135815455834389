/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  isBotExists: false,
  tokenId: '',
  botLink: '',
  botTitle: '',
  botLoading: false,
  botError: ''
};

export const botSlice = createSlice({
  name: Slices.bot,
  initialState,
  reducers: {
    setIsBotExists: (state, action) => {
      state.isBotExists = action.payload;
    },
    setBotTokenId: (state, action) => {
      state.tokenId = action.payload;
    },
    setBotLink: (state, action) => {
      state.botLink = action.payload;
    },
    setBotTitle: (state, action) => {
      state.botTitle = action.payload;
    },
    setBotLoading: (state, action) => {
      state.botLoading = action.payload;
    },
    setBotError: (state, action) => {
      state.botError = action.payload;
    }
  }
});

export const {
  setIsBotExists,
  setBotTokenId,
  setBotLink,
  setBotTitle,
  setBotLoading,
  setBotError
} = botSlice.actions;

export default botSlice.reducer;
