/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  typesPost: []
};

export const typePostSlice = createSlice({
  name: Slices.typePost,
  initialState,
  reducers: {
    setTypesPost: (state, action) => {
      state.typesPost = action.payload;
    },

    addTypePost: (state, action) => {
      state.typesPost.push(action.payload);
    },

    deleteTypePost: (state, action) => {
      state.typesPost = state.typesPost.filter((item) => item.id !== action.payload.deletedId);
    },

    editTypePost: (state, action) => {
      const { changedTypePost } = action.payload;

      state.typesPost = state.typesPost.map((item) => {
        if (item.id === changedTypePost.id) {
          return changedTypePost;
        }
        return item;
      });
    }
  }
});

export const { setTypesPost, addTypePost, deleteTypePost, editTypePost } = typePostSlice.actions;

export default typePostSlice.reducer;
