import React, { useState } from 'react';
import { apiUrl } from 'constant/data';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import s from './index.module.scss';

export const ImageItem = ({ image, file, itemId, handleDelete }) => {
  const [preview, setPreview] = useState(null);

  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }
  return (
    <div className={s.img__wrapper}>
      <div className={s.cover}>
        <IconButton
          onClick={() => handleDelete(itemId)}
          aria-label="delete"
          size="large"
          sx={{
            backgroundColor: '#fff',
            '&:hover': { backgroundColor: '#fff' }
          }}
        >
          <DeleteIcon sx={{ fontSize: '2rem' }} />
        </IconButton>
      </div>
      {file && !image && <img src={preview} alt="" />}

      {image && <img src={`${apiUrl}/api/${image.path}`} alt="" />}
    </div>
  );
};
