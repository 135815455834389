import SERVICE_API from 'api';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getOpenCurrencyAction,
  getSavedCurrencyAction,
  editSavedCurrencyAction
} from 'store/actions/currencyActions';
import { setAllCurrency, setSavedCurrency } from 'store/slices/currencySlice';

function* fetchOpenCurrencySaga() {
  try {
    const response = yield call(() => SERVICE_API.CurrencyAPI.getOpenCurrencies());

    yield put(setAllCurrency(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* fetchSavedCurrencySaga(action) {
  try {
    const response = yield call(() =>
      SERVICE_API.CurrencyAPI.getSavedCurrencies(action.payload.botId)
    );

    yield put(setSavedCurrency(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* editSavedCurrencySaga(action) {
  try {
    const { botId, currencyData } = action.payload;
    const response = yield call(() =>
      SERVICE_API.CurrencyAPI.editSavedCurrencies({ botId, currencyData })
    );

    yield put(setSavedCurrency(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* currencySaga() {
  yield takeEvery(getOpenCurrencyAction, fetchOpenCurrencySaga);
  yield takeEvery(getSavedCurrencyAction, fetchSavedCurrencySaga);
  yield takeEvery(editSavedCurrencyAction, editSavedCurrencySaga);
}
