import { OrdersFilterForm } from 'components/form/ordersFilterForm';
import { OrdersTable } from 'components/orders/ordersTable';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrdersAction } from 'store/actions/orderActions';
import { convertOrderFilterParams } from 'utils/convert';
import s from './index.module.scss';

export const Orders = () => {
  const dispatch = useDispatch();
  const botId = useSelector((state) => state.bot.tokenId);
  const page = useSelector((state) => state.orderFilter.page);
  const quantity = useSelector((state) => state.orderFilter.quantity);
  const search = useSelector((state) => state.orderFilter.search);
  const seller = useSelector((state) => state.orderFilter.seller);
  const buyer = useSelector((state) => state.orderFilter.buyer);
  const curSortOption = useSelector((state) => state.orderFilter.curSortOption);

  const queryParams = convertOrderFilterParams(
    page,
    quantity,
    search,
    seller,
    buyer,
    curSortOption
  );
  console.log('Orders ', queryParams);

  useEffect(() => {
    if (botId) dispatch(getOrdersAction({ botId, queryParams }));
  }, [botId, queryParams, dispatch]);

  return (
    <div className={s.container}>
      <OrdersFilterForm />
      <OrdersTable />
    </div>
  );
};
