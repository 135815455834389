import React, { useEffect, useState } from 'react';
import { Button, IconButton, Paper } from '@mui/material';
import { nanoid } from 'nanoid';
import DeleteIcon from '@mui/icons-material/Delete';
import { getCategoriesAction } from 'store/actions/categoriesActions';
import { getTypesPostAction } from 'store/actions/typesPostActions';
import { createPostAction, getAvailableCitiesForPostAction } from 'store/actions/postActions';
import { useSelector, useDispatch } from 'react-redux';
import { postCreateValidationSchema } from 'utils/validation';
import { getOpenCurrencyAction } from 'store/actions/currencyActions';
import { useNavigate } from 'react-router';
import { FormContainer } from '../formContainer';
import { FormikText } from '../inputs/text';
import { FormikSelect } from '../inputs/select';
import { FormikAutocomplete } from '../inputs/autocomplete';
import { ImageAddButton } from './imageAddButton';
import { ImageItem } from './imageItem';
import s from './index.module.scss';

export const AdCreateForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const botId = useSelector((state) => state.bot.tokenId);
  const allCurrency = useSelector((state) => state.currency.allCurrency);
  const categories = useSelector((state) => state.category.categories);
  const typesPost = useSelector((state) => state.typePost.typesPost);
  const availableCitiesForPosts = useSelector((state) => state.post.availableCitiesForPosts);
  const [photos, setPhotos] = useState([]);
  const [files, setFiles] = useState([]);

  const getAvailableCitiesForPost = (debouncedSearchValue) => {
    dispatch(getAvailableCitiesForPostAction(debouncedSearchValue));
  };

  useEffect(() => {
    if (botId) {
      dispatch(getCategoriesAction(botId));
      dispatch(getTypesPostAction(botId));
      dispatch(getOpenCurrencyAction());
    }
  }, []);

  const initialValues = {
    title: '',
    price: '',
    currencyId: '',
    categoriesId: '',
    typePostId: '',
    description: '',
    address: '',
    selectedCity: ''
  };

  const handleUploadPhoto = (event) => {
    const file = event.target.files[0];
    file.isUploading = true;
    file.id = nanoid(5);
    setPhotos([...photos, file]);
  };

  const handleDeletePhoto = (itemId) => {
    const filteredNewPhotos = photos.filter((item) => item.id !== itemId);
    setPhotos(filteredNewPhotos);
  };

  const handleUploadFile = (event) => {
    const file = event.target.files[0];
    file.isUploading = true;
    file.id = nanoid(5);
    setFiles([...files, file]);
  };

  const handleDeleteFile = (itemId) => {
    const filteredNewFiles = files.filter((item) => item.id !== itemId);
    setFiles(filteredNewFiles);
  };

  const onSubmit = (values) => {
    const formData = new FormData();
    const partOfDescription = values.description.slice(0, 300);

    if (values.title) formData.append('title', values.title);
    formData.append('userId', 'bcc65db1-feed-4f39-b571-c6e5902b9128'); // Todo bcc65db1-feed-4f39-b571-c6e5902b9128
    formData.append('published', true);
    formData.append('price', values.price || null);
    formData.append('address', values.address || null);
    formData.append('description', values.description || null);
    formData.append('partOfDescription', partOfDescription || null);
    formData.append('categoryId', values.categoriesId && values.price ? values.categoriesId : null);
    formData.append('typePostId', values.typePostId || null);
    formData.append('currencyId', values.currencyId || null);
    formData.append('city', values.selectedCity?.title || null);

    if (photos.length > 0) {
      photos.forEach((item) => formData.append('photos', item));
    } else {
      formData.append('photos', null);
    }

    if (files.length > 0) {
      files.forEach((item) => formData.append('documents', item));
    } else {
      formData.append('documents', null);
    }

    dispatch(createPostAction(formData));
    navigate('/ads');
  };

  return (
    <div className={s.form__wrapper}>
      <FormContainer
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={postCreateValidationSchema}
      >
        {(formik) => {
          const { errors, values } = formik;
          return (
            <>
              <div className={s.box}>
                <FormikText
                  name="title"
                  label="Название"
                  fullWidth
                  sx={{ marginBottom: '1.5rem' }}
                  error={!!errors.title}
                  helperText={errors.title}
                />

                <div className={s.input__group}>
                  <FormikText
                    name="price"
                    label="Цена"
                    fullWidth
                    sx={{ width: '48%' }}
                    error={!!errors.price}
                    helperText={errors.price}
                  />

                  <FormikSelect
                    disabled={!values.price}
                    backgroundColor="transparent"
                    name="currencyId"
                    label="Валюта"
                    options={allCurrency}
                    width="48%"
                  />
                </div>

                <div className={s.input__group}>
                  <FormikSelect
                    name="categoriesId"
                    label="Категория"
                    options={categories}
                    width="48%"
                    backgroundColor="transparent"
                  />
                  <FormikSelect
                    name="typePostId"
                    label="Тип поста"
                    options={typesPost}
                    width="48%"
                    backgroundColor="transparent"
                  />
                </div>

                <div className={s.city__input__wrapper}>
                  <FormikAutocomplete
                    width="47%"
                    name="selectedCity"
                    label="Город"
                    getOptionsCallback={getAvailableCitiesForPost}
                    options={availableCitiesForPosts}
                    optionName="title"
                  />
                </div>

                <FormikText
                  multiline
                  minRows={7}
                  maxRows={7}
                  name="description"
                  label="Описание"
                  fullWidth
                  sx={{ marginBottom: '1.5rem' }}
                  error={!!errors.description}
                  helperText={errors.description}
                />

                <FormikText
                  name="address"
                  label="Адрес"
                  fullWidth
                  error={!!errors.address}
                  helperText={errors.address}
                />
              </div>

              <div className={s.photo__list}>
                <ImageAddButton handleUploadPhoto={handleUploadPhoto} />
                {!!photos.length &&
                  photos.map((file) => (
                    <ImageItem
                      key={file.id}
                      itemId={file.id}
                      file={file}
                      handleDelete={handleDeletePhoto}
                    />
                  ))}
              </div>

              <Paper elevation={0} className={s.file__box}>
                <h4 className={s.file__box__title}>файлы</h4>

                <div className={s.file__list}>
                  {!!files.length &&
                    files.map((item) => (
                      <div className={s.file__list__item} key={item.id}>
                        <p className={s.file__title}>{item?.name}</p>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => handleDeleteFile(item?.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ))}
                </div>

                <Button
                  component="label"
                  type="button"
                  variant="outlined"
                  className={s.button__add__file}
                  color="secondary"
                >
                  добавить файл
                  <input hidden multiple type="file" onChange={handleUploadFile} />
                </Button>
              </Paper>

              <Button variant="contained" type="submit" sx={{ color: '#fff', marginTop: '1rem' }}>
                сохранить
              </Button>
            </>
          );
        }}
      </FormContainer>
    </div>
  );
};
