/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  isNavigatorExists: false,
  navigatorInfo: null,
  loading: false,
  navigatorError: ''
};

export const navigatorSlice = createSlice({
  name: Slices.navigator,
  initialState,
  reducers: {
    setIsNavigatorExists: (state, action) => {
      state.isNavigatorExists = action.payload;
    },

    setNavigatorInfo: (state, action) => {
      state.navigatorInfo = action.payload;
    },
    setNavigatorLoading: (state, action) => {
      state.loading = action.payload;
    },
    setNavigatorError: (state, action) => {
      state.navigatorError = action.payload;
    }
  }
});

export const { setIsNavigatorExists, setNavigatorInfo, setNavigatorLoading, setNavigatorError } =
  navigatorSlice.actions;

export default navigatorSlice.reducer;
