import API from 'api/rest';

class WebAPI {
  getWebData = (clientId) => API.get(`/api/web/${clientId}`);

  createWebData = ({ clientId, body }) => API.post(`/api/web/${clientId}`, body);

  editWebData = ({ id, body }) => API.put(`/api/web/${id}`, body);

  deleteWebLogo = (webId) => API.delete(`/api/web/logo/${webId}`);
}

export default new WebAPI();
