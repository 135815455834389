import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1cc700'
    },
    secondary: {
      main: '#9e9e9e'
    }
  }
});
