import React from 'react';
import clsx from 'clsx';
import Collapsible from 'react-collapsible';
import Tooltip from '@mui/material/Tooltip';
import { sidebarMenuLinks, sidebarSubMenuLinks } from 'constant/data';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from 'components/logo';
import { Typography } from '@mui/material';
import { BoxSVG, ListSVG, StatisticsSVG, TelegramSVG, UserSVG } from 'resources/svg';
import s from './index.module.scss';

export const Sidebar = ({ sidebarIsOpen }) => {
  const { pathname } = useLocation();

  return (
    <div className={clsx(s.sidebar, sidebarIsOpen && s.active)}>
      <div className={s.sidebar__inner}>
        <div className={s.sidebar__logo}>
          <Logo />
        </div>

        <div className={s.menu}>
          {/* {sidebarMenuLinks.map((item) => {
            const iconComponent = React.cloneElement(item.icon, {
              color: pathname === item.pathName ? '#1cc700' : '#E9E9E9'
            });
            return (
              <div className={s.menu__item__link} key={item.id}>
                <div className={clsx(s.menu__item, pathname === item.pathName && s.active)}>
                  {iconComponent}
                  <Tooltip
                    title={<Typography fontSize={12}>этот раздел пока в разработке</Typography>}
                    placement="top-start"
                  >
                    <p className={s.menu__item__text}>{item.text}</p>
                  </Tooltip>
                </div>
              </div>
            );
          })} */}

          <div className={s.menu__item__link}>
            <div className={clsx(s.menu__item, pathname === '/statistic' && s.active)}>
              <StatisticsSVG />
              <Tooltip
                title={<Typography fontSize={12}>этот раздел пока в разработке</Typography>}
                placement="top-start"
              >
                <p className={s.menu__item__text__disabled}>Статистика</p>
              </Tooltip>
            </div>
          </div>

          {/* <div className={s.menu__item__link}>
            <div className={clsx(s.menu__item, pathname === '/users' && s.active)}>
              <UserSVG />
              <Tooltip
                title={<Typography fontSize={12}>этот раздел пока в разработке</Typography>}
                placement="top-start"
              >
                <p className={s.menu__item__text__disabled}>Пользователи</p>
              </Tooltip>
            </div>
          </div> */}

          <div className={s.menu__item__link}>
            <Link to="/users" className={clsx(s.menu__item, pathname === '/users' && s.active)}>
              <UserSVG color={pathname === '/users' ? '#1CC700' : '#AFAFAF'} />
              <p className={s.menu__item__text}>Пользователи</p>
            </Link>
          </div>

          <div className={s.menu__item__link}>
            <Link
              to="/ads"
              className={clsx(s.menu__item, pathname.slice(0, 4) === '/ads' && s.active)}
            >
              <ListSVG color={pathname.slice(0, 4) === '/ads' ? '#1CC700' : '#AFAFAF'} />
              <p className={s.menu__item__text}>Объявления</p>
            </Link>
          </div>

          <div className={s.menu__item__link}>
            <Link
              to="/orders"
              className={clsx(s.menu__item, pathname.slice(0, 7) === '/orders' && s.active)}
            >
              <BoxSVG color={pathname.slice(0, 7) === '/orders' ? '#1CC700' : '#AFAFAF'} />
              <p className={s.menu__item__text}>Заказы</p>
            </Link>
          </div>
        </div>

        <div className={s.sum_menu}>
          {sidebarSubMenuLinks.map((item) => (
            <Collapsible
              open
              trigger={
                <div className={s.accordion__trigger__heading}>
                  {item.icon}
                  <p className={s.accordion__trigger__text}>{item.text}</p>
                </div>
              }
              key={item.id}
              className={s.accordion}
              openedClassName={s.accordion}
              triggerClassName={s.accordion__trigger}
              triggerOpenedClassName={clsx(s.accordion__trigger, s.accordion__trigger_open)}
            >
              <ul>
                {item?.categories.map((subItem) => (
                  <li
                    className={clsx(
                      s.accordion__subitem,
                      pathname === subItem.pathName && s.active
                    )}
                    key={subItem.id}
                  >
                    <Link to={subItem.pathName}>{subItem.text}</Link>
                  </li>
                ))}
              </ul>
            </Collapsible>
          ))}
        </div>

        <div className={s.bottom}>
          <p className={s.bottom__text}>
            Нужна помощь?
            <br /> Мы на связи в телеграм:
          </p>
          <a className={s.bottom__link} href="https://t.me/techsupport_vitrina" target="blank">
            <div className={s.bottom__link__svg}>
              <TelegramSVG />
            </div>
            <p>@techsupport_vitrina</p>
          </a>
        </div>
      </div>
    </div>
  );
};
