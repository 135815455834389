import { Container, Paper } from '@mui/material';
import { LoginForm } from 'components/form/loginForm';
import { Loader } from 'components/loader';
import React from 'react';
import { useSelector } from 'react-redux';
import s from './index.module.scss';

export const Login = () => {
  const loading = useSelector((state) => state.auth.loading);

  return (
    <Container maxWidth="sm">
      {loading ? (
        <div className={s.loader__container}>
          <Loader />
        </div>
      ) : (
        <Paper className={s.content} elevation={3}>
          <h4 className={s.title}>Авторизация</h4>
          <LoginForm />
        </Paper>
      )}
    </Container>
  );
};
