import SERVICE_API from 'api';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getChannelsMiniAction,
  addChannelMiniAction,
  editChannelMiniAction,
  deleteChannelMiniAction
} from 'store/actions/channelsMiniActions';
import {
  setChannelsMini,
  addChannelsMini,
  editChannelsMini,
  deleteChannelsMini
} from 'store/slices/channelMiniSlice';

function* fetchChannelMiniSaga(action) {
  try {
    const response = yield call(() =>
      SERVICE_API.ChannelMiniAPI.getChannelsMini(action.payload.clientId)
    );

    yield put(setChannelsMini(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* addChannelMiniSaga(action) {
  const { clientId, channelMiniData } = action.payload;

  try {
    const response = yield call(() =>
      SERVICE_API.ChannelMiniAPI.createChannelMini({ clientId, channelMiniData })
    );

    if (response?.data) {
      yield put(addChannelsMini(response.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* editChannelMiniSaga(action) {
  const { clientId, channelMiniData } = action.payload;

  try {
    const response = yield call(() =>
      SERVICE_API.ChannelMiniAPI.editChannelMini({ clientId, channelMiniData })
    );

    if (response?.data) {
      yield put(editChannelsMini({ changedChannelsMini: response.data }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteChannelMiniSaga(action) {
  const { clientId, channelMiniData } = action.payload;

  try {
    const response = yield call(() =>
      SERVICE_API.ChannelMiniAPI.deleteChannelMini({ clientId, channelMiniData })
    );

    if (response?.data) {
      yield put(deleteChannelsMini({ deletedId: channelMiniData.id }));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* channelMiniSaga() {
  yield takeEvery(getChannelsMiniAction, fetchChannelMiniSaga);
  yield takeEvery(addChannelMiniAction, addChannelMiniSaga);
  yield takeEvery(editChannelMiniAction, editChannelMiniSaga);
  yield takeEvery(deleteChannelMiniAction, deleteChannelMiniSaga);
}
