import React from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { navigatorValidationSchema } from 'utils/validation';
import { editNavigatorAction } from 'store/actions/navigatorActions';
import { PromptButton } from 'components/buttons/promptButton';
import { useModal } from 'hooks/useModal';
import { modals } from 'components/modal';
import { ClientName } from 'components/prompts/clientName';
import { BotTokenPrompt } from 'components/prompts/botTokenPrompt';
import { MainDescPrompt } from 'components/prompts/mainDescPrompt';
import { MiniDescPrompt } from 'components/prompts/miniDescPrompt';
import { FormContainer } from '../formContainer';
import { FormikText } from '../inputs/text';
import s from './index.module.scss';

export const NavigatorForm = () => {
  const navigatorInfo = useSelector((state) => state.navigator.navigatorInfo);
  const navigatorError = useSelector((state) => state.navigator.navigatorError);
  const tokenError = navigatorError === 'Bot token invalid' ? 'Невалидный токен' : '';

  const dispatch = useDispatch();
  const navId = navigatorInfo.token;
  const { showModal, hideModal } = useModal();

  const showModalPrompt = (content) => {
    showModal(modals.ModalPrompt, { showModal, hideModal, content });
  };

  const initialValues = {
    token: navigatorInfo?.token,
    miniDesc: navigatorInfo?.miniDesc,
    mainDesc: navigatorInfo?.mainDesc,
    typeNavigation: navigatorInfo?.typeNavigation
  };

  const onSubmit = (values) => {
    const info = { ...values, typeNavigation: 'STANDART' };
    dispatch(editNavigatorAction({ navId, info }));
  };

  return (
    <div className={s.form__container}>
      <FormContainer
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={navigatorValidationSchema}
      >
        {(formik) => {
          const { errors } = formik;
          return (
            <>
              <div className={s.input__wrapper}>
                <div className={s.input__inner}>
                  <FormikText
                    name="token"
                    fullWidth
                    className={s.input}
                    error={!!errors.token || !!tokenError}
                    label="Токен бота"
                  />

                  {tokenError && <p className={s.input__error}>{tokenError}</p>}
                </div>

                <PromptButton callbackHandler={() => showModalPrompt(<BotTokenPrompt />)} />
              </div>

              <div className={s.input__wrapper}>
                <FormikText
                  label="Краткое описание проекта"
                  name="miniDesc"
                  fullWidth
                  className={s.input}
                  multiline
                  minRows={3}
                  error={!!errors.miniDesc}
                />
                <PromptButton callbackHandler={() => showModalPrompt(<MiniDescPrompt />)} />
              </div>

              <div className={s.input__wrapper}>
                <FormikText
                  label="Полное описание проекта"
                  name="mainDesc"
                  fullWidth
                  className={s.input}
                  multiline
                  minRows={5}
                  error={!!errors.mainDesc}
                />
                <PromptButton callbackHandler={() => showModalPrompt(<MainDescPrompt />)} />
              </div>

              <Button type="submit" variant="contained" size="large" className={s.button__save}>
                сохранить
              </Button>
            </>
          );
        }}
      </FormContainer>
    </div>
  );
};
