import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Chip,
  TableFooter,
  TablePagination
} from '@mui/material';
import { getCurSortOrderOption } from 'utils/orderSort';
import DoneIcon from '@mui/icons-material/Done';
import { convertDate } from 'utils/convert';
import { setCurPage, setCurSortOption, setQuantity } from 'store/slices/orderFilterSlice';
import s from './index.module.scss';

export const OrdersTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.order.orders);
  const total = useSelector((state) => state.order.total);
  const page = useSelector((state) => state.orderFilter.page);
  const quantity = useSelector((state) => state.orderFilter.quantity);
  const curSortOption = useSelector((state) => state.orderFilter.curSortOption);
  // console.log('OrdersTable ', orders);

  const handleChangePage = (event, newPage) => {
    dispatch(setCurPage(newPage + 1));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setQuantity(+event.target.value));
    dispatch(setCurPage(1));
  };

  const handleSort = (fieldName) => {
    const newSortOption = getCurSortOrderOption(fieldName, curSortOption);
    dispatch(setCurSortOption(newSortOption));
  };

  const handleNavigate = (id) => {
    // navigate(`/orders/${id}`);
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table sx={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>Номер</TableCell>
              <TableCell onClick={() => handleSort('price')}>
                <div className={s.cell}>
                  <p>Цена</p>
                  {curSortOption.fieldName === 'price' && (
                    <span className={s.cell__icon}>
                      {curSortOption.direction === 'asc' ? (
                        <VerticalAlignBottomIcon />
                      ) : (
                        <VerticalAlignTopIcon />
                      )}
                    </span>
                  )}
                </div>
              </TableCell>

              <TableCell onClick={() => handleSort('quantity')}>
                <div className={s.cell}>
                  <p>Кол-во</p>
                  {curSortOption.fieldName === 'quantity' && (
                    <span className={s.cell__icon}>
                      {curSortOption.direction === 'asc' ? (
                        <VerticalAlignBottomIcon />
                      ) : (
                        <VerticalAlignTopIcon />
                      )}
                    </span>
                  )}
                </div>
              </TableCell>
              {/* <TableCell>Продавец</TableCell> */}
              <TableCell>Покупатель</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell onClick={() => handleSort('date')}>
                <div className={s.cell}>
                  <p>Дата заказа</p>
                  {curSortOption.fieldName === 'date' && (
                    <span className={s.cell__icon}>
                      {curSortOption.direction === 'asc' ? (
                        <VerticalAlignBottomIcon />
                      ) : (
                        <VerticalAlignTopIcon />
                      )}
                    </span>
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {orders.length > 0 &&
              orders.map((item) => (
                <TableRow key={item.id} className={s.row}>
                  <TableCell>{item?.event ? item?.event : '-'}</TableCell>
                  {/* TODO первый элемент массива */}
                  <TableCell>{item?.price || '-'}</TableCell>
                  <TableCell>{item?.ordersCount || '-'}</TableCell>
                  {/* <TableCell>
                    <div>
                      {item?.seller?.username && (
                        <a
                          className={s.user__link}
                          href={`https://t.me/${item?.seller?.username}`}
                          target="blank"
                        >{`@${item?.seller?.username}`}</a>
                      )}{' '}
                      {item?.seller?.firstName && <p>{item?.seller?.firstName}</p>}{' '}
                      {item?.seller?.phone && <p>{item?.seller?.phone}</p>}{' '}
                    </div>
                  </TableCell> */}
                  <TableCell>
                    <div>
                      {item?.buyer?.username && (
                        <a
                          className={s.user__link}
                          href={`https://t.me/${item?.buyer?.username}`}
                          target="blank"
                        >{`@${item?.buyer?.username}`}</a>
                      )}{' '}
                      {item?.buyer?.firstName && <p>{item?.buyer?.firstName}</p>}{' '}
                      {item?.phone && <p>{item?.phone}</p>}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Chip label="Активный" icon={<DoneIcon />} variant="outlined" color="primary" />
                  </TableCell>
                  <TableCell>{convertDate(item.createAt)}</TableCell>
                </TableRow>
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 8, 20]}
                count={total || 0}
                rowsPerPage={quantity}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
