import React from 'react';
import { Login } from 'pages/login';
import { Users } from 'pages/users';
import { AdsPage } from 'pages/adsPage';
import { Statistic } from 'pages/statistic';
import { Orders } from 'pages/orders';
import { SettingsClient } from 'pages/settingsClient';
import { SettingsCatalog } from 'pages/settingsCatalog';
import { SettingsNavBot } from 'pages/settingsNavBot';
import { MainLayout } from 'components/layout/mainLayout';
import { Ad } from 'pages/ad';
import { AdNew } from 'pages/adNew';
import { Order } from 'pages/order';
import { AppPaths } from './constants';

export const routes = [
  {
    index: true,
    path: AppPaths.login,
    label: 'Вход',
    component: (route) => <Login />,
    routes: []
  },
  {
    index: true,
    path: AppPaths.statistic,
    label: 'Статистика',
    component: (route) => (
      <MainLayout route={route}>
        <Statistic />
      </MainLayout>
    ),
    routes: []
  },
  {
    index: true,
    path: AppPaths.users,
    label: 'Пользователи',
    component: (route) => (
      <MainLayout route={route}>
        <Users />
      </MainLayout>
    ),
    routes: []
  },

  {
    index: true,
    path: AppPaths.ads,
    label: 'Объявления',
    component: (route) => (
      <MainLayout route={route}>
        <AdsPage />
      </MainLayout>
    ),
    routes: []
  },
  {
    index: true,
    path: AppPaths.adNew,
    label: 'Новое объявление',
    component: (route) => (
      <MainLayout route={route}>
        <AdNew />
      </MainLayout>
    ),
    routes: []
  },
  {
    index: true,
    path: AppPaths.ad,
    label: 'Объявление',
    component: (route) => (
      <MainLayout route={route}>
        <Ad />
      </MainLayout>
    ),
    routes: []
  },
  {
    index: true,
    path: AppPaths.orders,
    label: 'Заказы',
    component: (route) => (
      <MainLayout route={route}>
        <Orders />
      </MainLayout>
    ),
    routes: []
  },
  {
    index: true,
    path: AppPaths.order,
    label: 'Заказ',
    component: (route) => (
      <MainLayout route={route}>
        <Order />
      </MainLayout>
    ),
    routes: []
  },
  {
    index: true,
    path: AppPaths.settingsClient,
    label: 'Клиент',
    component: (route) => (
      <MainLayout route={route}>
        <SettingsClient />
      </MainLayout>
    ),
    routes: []
  },
  {
    index: true,
    path: AppPaths.settingsCatalog,
    label: 'Настройки каталог',
    component: (route) => (
      <MainLayout route={route}>
        <SettingsCatalog />
      </MainLayout>
    ),
    routes: []
  },
  {
    index: true,
    path: AppPaths.settingsNavBot,
    label: 'Навигационный бот',
    component: (route) => (
      <MainLayout route={route}>
        <SettingsNavBot />
      </MainLayout>
    ),
    routes: []
  }
];
