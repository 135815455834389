import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { theme } from 'styles/theme';
import { ModalProvider } from 'hooks/useModal';
import store from 'store';
import App from 'App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider preventDuplicate maxSnack={3}>
          <ModalProvider>
            <App />
          </ModalProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);
