import { CartSVG } from 'resources/svg';
import React from 'react';
import s from './index.module.scss';

export const Logo = ({ withIcon = true }) => (
  <div className={s.logo__wrapper}>
    {withIcon && (
      <div className={s.logo__icon}>
        <CartSVG />
      </div>
    )}
    <h3 className={s.logo__text}>
      Vitrina <span className={s.logo__text__green}>bot</span>
    </h3>
  </div>
);
