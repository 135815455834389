import SERVICE_API from 'api';
import { call, put, takeEvery } from 'redux-saga/effects';
import { loginAction, editClientAction } from 'store/actions/authAction';
import {
  setClientId,
  setClientFullName,
  setClientPhone,
  setClientEmail,
  setClientCommunityName,
  setLoading
} from 'store/slices/authSlice';
import { setIsBotExists, setBotTokenId, setBotLink, setBotTitle } from 'store/slices/botSlice';
import { setNavigatorInfo, setIsNavigatorExists } from 'store/slices/navigatorSlice';
import { setNotification } from 'store/slices/notificationsSlice';

function* loginSaga(action) {
  try {
    yield put(setLoading(true));
    const loginResponse = yield call(() =>
      SERVICE_API.AuthAPI.login(action.payload.authCredential)
    );

    // console.log('Client-Response ', loginResponse);
    const clientId = loginResponse.data.id;

    yield put(setClientId(clientId));
    yield put(setClientFullName(loginResponse.data.fullname));
    yield put(setClientPhone(loginResponse.data.phone));
    yield put(setClientEmail(loginResponse.data.email));
    yield put(setClientCommunityName(loginResponse.data.communityName));

    const botResponse = yield call(() => SERVICE_API.BotAPI.getBotInfo(clientId));
    // console.log('botResponse ', botResponse);

    if (botResponse.data) {
      yield put(setIsBotExists(true));
      yield put(setBotTokenId(botResponse.data.token));
      yield put(setBotLink(botResponse.data.link));
      yield put(setBotTitle(botResponse.data.title));
    }

    const navigatorResponse = yield call(() => SERVICE_API.NavigatorAPI.getNavigatorInfo(clientId));
    // console.log('navigatorResponse ', navigatorResponse);
    if (navigatorResponse.data) {
      yield put(setIsNavigatorExists(true));
      yield put(setNavigatorInfo(navigatorResponse.data));
    }

    localStorage.setItem('authCredential', JSON.stringify(action.payload.authCredential));

    yield put(setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(setLoading(false));
  }
}

function* editClientSaga(action) {
  try {
    const { clientInfo, clientId } = action.payload;
    const response = yield call(() => SERVICE_API.AuthAPI.editClient({ clientInfo, clientId }));

    yield put(setClientFullName(response.data.fullname));
    yield put(setClientPhone(response.data.phone));
    yield put(setClientEmail(response.data.email));
    yield put(
      setNotification({
        message: 'Настройки сохранены',
        options: {
          variant: 'success'
        }
      })
    );
  } catch (error) {
    console.log(error);
  }
}

export function* authSaga() {
  yield takeEvery(loginAction, loginSaga);
  yield takeEvery(editClientAction, editClientSaga);
}
