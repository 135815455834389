import React from 'react';
import screen1 from 'resources/images/mainDescScreen.png';
import s from './index.module.scss';

export const MainDescPrompt = () => (
  <div className={s.container}>
    <h4 className={s.title}>Полное описание проекта</h4>
    <p className={s.text}>
      Полное описание вашего проекта будет отображаться при входе пользователями в навигационный
      бот, перед прохождением опроса. А также на web-сайте на странице О проекте в описании. Пример
      на скриншоте.Навигационный бот вызывается кнопкой Главное меню в функционале бота работы с
      объявлениями.
    </p>

    <div className={s.screen__container}>
      <img src={screen1} alt="screen" />
    </div>
  </div>
);
