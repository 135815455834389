/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { CrossSVG } from 'resources/svg';
import s from './index.module.scss';

export const ModalPrompt = ({ hideModal, content }) => {
  const hideAllModal = (e) => {
    if (e.target.id === 'ModalBackdrop') hideModal();
  };
  return (
    <div className={s.backdrop} onClick={hideAllModal} id="ModalBackdrop">
      <div className={s.container__scroll}>
        <div className={s.container__flex} onClick={hideModal}>
          <div className={s.content} onClick={(e) => e.stopPropagation()}>
            <div className={s.button__close} onClick={hideModal}>
              <CrossSVG />
            </div>
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};
