/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOpenCurrencyAction,
  getSavedCurrencyAction,
  editSavedCurrencyAction
} from 'store/actions/currencyActions';

import { Button, Checkbox, FormGroup, FormControlLabel, Paper } from '@mui/material';
import { useModal } from 'hooks/useModal';
import { modals } from 'components/modal';
import { PromptButton } from 'components/buttons/promptButton';
import { CurrencyPrompt } from 'components/prompts/currencyPrompt';
import s from './index.module.scss';

export const CatalogCurrency = () => {
  const dispatch = useDispatch();
  const botId = useSelector((state) => state.bot.tokenId);
  const allCurrency = useSelector((state) => state.currency.allCurrency);
  const savedCurrency = useSelector((state) => state.currency.savedCurrency);
  const [currency, setCurrency] = useState([]);
  const { showModal, hideModal } = useModal();

  const showModalPrompt = (content) => {
    showModal(modals.ModalPrompt, { showModal, hideModal, content });
  };

  useEffect(() => {
    dispatch(getOpenCurrencyAction());
    dispatch(getSavedCurrencyAction(botId));
  }, []);

  useEffect(() => {
    setCurrency(allCurrency);

    const currencies = allCurrency.filter((item) => {
      if (savedCurrency.find((i) => i.id === item.id)) {
        return item;
      }
    });
    setCurrency(currencies);
  }, [allCurrency, savedCurrency]);

  const handleChange = (checkboxItem) => {
    const { id } = checkboxItem;

    if (!!currency.find((item) => item.id === id)) {
      setCurrency(currency.filter((i) => i.id !== id));
    } else {
      setCurrency([...currency, checkboxItem]);
    }
  };

  const checkIsChecked = (item) => !!currency.find((i) => i.id === item.id);

  const saveCurrency = () => {
    const currenciesId = currency.map((item) => item.id);
    const currencyData = {
      currenciesId
    };

    dispatch(editSavedCurrencyAction({ currencyData, botId }));
  };

  return (
    <Paper elevation={0} className={s.container}>
      <div className={s.heading}>
        <h4 className={s.title}>Валюты </h4>
        <div className={s.button__prompt__wrapper}>
          <PromptButton callbackHandler={() => showModalPrompt(<CurrencyPrompt />)} />
        </div>
      </div>

      <div className={s.form__container}>
        <FormGroup>
          {allCurrency.map((item) => (
            <FormControlLabel
              key={item.id}
              label={item.title}
              control={
                <Checkbox checked={checkIsChecked(item)} onChange={() => handleChange(item)} />
              }
            />
          ))}
        </FormGroup>

        <Button
          variant="contained"
          className={s.button__save}
          type="submit"
          size="large"
          onClick={saveCurrency}
        >
          сохранить
        </Button>
      </div>
    </Paper>
  );
};
