/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { EditChannelForm } from 'components/form/editChannelForm';
import s from './index.module.scss';

export const ModalEditChannel = ({ hideModal, channelData }) => {
  const hideAllModal = (e) => {
    if (e.target.id === 'ModalBackdrop') hideModal();
  };
  return (
    <div className={s.backdrop} onClick={hideAllModal} id="ModalBackdrop">
      <div className={s.container__scroll}>
        <div className={s.container__flex} onClick={hideModal}>
          <div className={s.content} onClick={(e) => e.stopPropagation()}>
            <h4 className={s.title}>Редактирование чата/канала</h4>
            <EditChannelForm channelData={channelData} hideModal={hideModal} />
          </div>
        </div>
      </div>
    </div>
  );
};
