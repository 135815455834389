/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  users: [],
  total: 0
};

export const userSlice = createSlice({
  name: Slices.user,
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    }
  }
});

export const { setUsers, setTotal } = userSlice.actions;

export default userSlice.reducer;
