import React from 'react';
import screen1 from 'resources/images/BotTokenPromptScreen.png';
import s from './index.module.scss';

export const PeriodPublicationPrompt = () => (
  <div className={s.container}>
    <h4 className={s.title}>Количество дней активности объявления</h4>
    <p className={s.text}>
      Срок, в течение которого запрещена повторная публикация объявления о продаже этого-же
      товара/услуги. Период, через который объявление будет автоматически повторно опубликовано.
    </p>

    {/* <div className={s.screen__container}>
      <img src={screen1} alt="screen" />
    </div> */}
  </div>
);
