import SERVICE_API from 'api';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  addCategoryAction,
  getCategoriesAction,
  deleteCategoryAction,
  editCategoryAction,
  changePositionCategoryAction
} from 'store/actions/categoriesActions';
import {
  setCategories,
  addCategory,
  deleteCategory,
  editCategory
} from 'store/slices/categorySlice';

function* fetchCategorySaga(action) {
  try {
    const response = yield call(() => SERVICE_API.CategoryAPI.getCategories(action.payload.id));
    yield put(setCategories(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* addCategorySaga(action) {
  const { id: botId, title } = action.payload;

  try {
    const response = yield call(() => SERVICE_API.CategoryAPI.addCategory({ botId, title }));

    if (response?.data) {
      const createdCategory = {
        title: response.data.title,
        id: response.data.id
      };

      yield put(addCategory(createdCategory));
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteCategorySaga(action) {
  const { botId, id } = action.payload;

  try {
    const response = yield call(() => SERVICE_API.CategoryAPI.deleteCategory({ botId, id }));
    if (response?.data) {
      yield put(deleteCategory({ deletedId: id }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* editCategorySaga(action) {
  const { botId, category } = action.payload;

  try {
    const response = yield call(() => SERVICE_API.CategoryAPI.editCategory({ botId, category }));

    if (response?.data) {
      yield put(editCategory({ changedCategory: response.data }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* changePositionCategorySaga(action) {
  const { botId, newOrder } = action.payload;

  try {
    const response = yield call(() =>
      SERVICE_API.CategoryAPI.changePositionCategory({ botId, newOrder })
    );

    yield put(setCategories(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* categorySaga() {
  yield takeEvery(getCategoriesAction, fetchCategorySaga);
  yield takeEvery(addCategoryAction, addCategorySaga);
  yield takeEvery(deleteCategoryAction, deleteCategorySaga);
  yield takeEvery(editCategoryAction, editCategorySaga);
  yield takeEvery(changePositionCategoryAction, changePositionCategorySaga);
}
