import UserAPI from './userApi.js';
import CategoryAPI from './categoryApi';
import TypePostAPI from './typePostApi';
import ChannelAPI from './channelApi';
import AuthAPI from './authApi';
import NavigatorAPI from './navigatorApi';
import BotAPI from './botApi';
import CurrencyAPI from './currencyApi';
import ChannelMiniAPI from './channelMiniApi';
import QuestionAPI from './questionApi';
import WebAPI from './webApi';
import PostAPI from './postApi.js';
import OptionsAPI from './optionsApi';
import OrderAPI from './orderApi.js';

const SERVICE_API = {
  UserAPI,
  CategoryAPI,
  TypePostAPI,
  ChannelAPI,
  AuthAPI,
  NavigatorAPI,
  BotAPI,
  CurrencyAPI,
  ChannelMiniAPI,
  QuestionAPI,
  WebAPI,
  PostAPI,
  OptionsAPI,
  OrderAPI
};

export default SERVICE_API;
