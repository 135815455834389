import React from 'react';
import s from './index.module.scss';

export const CategoryPrompt = () => (
  <div className={s.container}>
    <h4 className={s.title}>Категории </h4>
    <p className={s.text}>
      Распределение взаимосвязанных товаров/услуг по группам. Чтобы ваши пользователи могли выбирать
      категорию при создании объявления и при поиске в боте и web-каталоге. Можно добавить
      неограниченное количество категорий.
    </p>
  </div>
);
