import { ModalCreateChannel } from './modalCreateChannel';
import { ModalCreateCategory } from './modalCreateCategory';
import { ModalEditChannel } from './modalEditChannel';
import { ModalCreateTypePost } from './modalCreateTypePost';
import { ModalEditCategory } from './modalEditCategory';
import { ModalEditTypePost } from './modalEditTypePost';
import { ModalCreateChannelMini } from './modalCreateChannelMini';
import { ModalEditChannelMini } from './modalEditChannelMini';
import { ModalPrompt } from './modalPrompt';
import { ModalConfirm } from './modalConfirm';
import { ModalCreateQuestion } from './modalCreateQuestion';
import { ModalEditQuestion } from './modalEditQuestion';

export const modals = {
  ModalCreateChannel,
  ModalCreateCategory,
  ModalCreateTypePost,
  ModalEditChannel,
  ModalEditCategory,
  ModalEditTypePost,
  ModalCreateChannelMini,
  ModalEditChannelMini,
  ModalPrompt,
  ModalConfirm,
  ModalCreateQuestion,
  ModalEditQuestion
};
