import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useField, useFormikContext } from 'formik';

export const FormikSelect = (props) => {
  const {
    options,
    name = '',
    label,
    onChange,
    width = '',
    backgroundColor = '#fff',
    disabled = false
  } = props;
  const [field] = useField(name);
  const form = useFormikContext();

  const handleChange = (e) => {
    form.setFieldValue(name, e.target.value);
    if (onChange) onChange(e);
  };

  const inputValue = field.value || '';

  return (
    <FormControl sx={{ minWidth: 210, width }}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        sx={{ backgroundColor }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={inputValue}
        label={label}
        onChange={handleChange}
        disabled={disabled}
      >
        {!!options?.length &&
          options.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
