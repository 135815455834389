import React from 'react';
import { Button, Paper } from '@mui/material';
import { FormContainer } from 'components/form/formContainer';
import { useModal } from 'hooks/useModal';
import { PromptButton } from 'components/buttons/promptButton';
import { PeriodPublicationPrompt } from 'components/prompts/periodPublicationPrompt';
import { FormikText } from 'components/form/inputs/text';
import { modals } from 'components/modal';
import s from './index.module.scss';

export const CatalogPublication = () => {
  const { showModal, hideModal } = useModal();

  const showModalPrompt = (content) => {
    showModal(modals.ModalPrompt, { showModal, hideModal, content });
  };
  const initialValues = {
    periodPublication: 0
  };

  const onSubmit = (values) => {
    console.log('CatalogPublication', values);
  };

  return (
    <div className={s.container}>
      <Paper elevation={0} className={s.paper}>
        <FormContainer
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {(formik) => {
            const { errors } = formik;

            return (
              <>
                <div className={s.input__container}>
                  <FormikText
                    name="periodPublication"
                    fullWidth
                    label="Количество дней активности объявления."
                    type="number"
                  />
                  <PromptButton
                    callbackHandler={() => showModalPrompt(<PeriodPublicationPrompt />)}
                  />
                </div>

                <div className={s.button__group}>
                  <Button variant="contained" className={s.button__save} type="submit" size="large">
                    сохранить
                  </Button>
                </div>
              </>
            );
          }}
        </FormContainer>
      </Paper>
    </div>
  );
};
