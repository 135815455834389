import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import s from './index.module.scss';

export const CustomNoRowsOverlay = ({ text }) => (
  <div className={s.no_rows__container}>
    <ErrorOutlineIcon className={s.no_rows__icon} />
    <p className={s.no_rows__text}>{text}</p>
  </div>
);
