import { createAction } from '@reduxjs/toolkit';

export const createNavigatorAction = createAction('createNavigator', (navBotData) => ({
  payload: {
    navBotData
  }
}));

export const editNavigatorAction = createAction('editNavigator', ({ navId, info }) => ({
  payload: {
    navId,
    info
  }
}));
