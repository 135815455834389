/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  page: 1,
  quantity: 5,
  categoriesId: null,
  categoriesName: null,
  city: null,
  author: null,
  minPrice: '',
  maxPrice: '',
  search: '',
  curSortOption: { value: 0, direction: 'asc', fieldName: 'date' }
};

export const postFilterSlice = createSlice({
  name: Slices.postFilter,
  initialState,
  reducers: {
    setCurPage: (state, action) => {
      state.page = action.payload;
    },
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
    setCategoriesId: (state, action) => {
      state.categoriesId = action.payload;
      state.page = 1;
    },
    setCategoriesName: (state, action) => {
      state.categoriesName = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
      state.page = 1;
    },
    setAuthor: (state, action) => {
      state.author = action.payload;
      state.page = 1;
    },
    setMinPrice: (state, action) => {
      state.minPrice = action.payload;
      state.page = 1;
    },
    setMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
      state.page = 1;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setCurSortOption: (state, action) => {
      state.curSortOption = action.payload;
      state.page = 1;
    },
    resetFilters: (state, action) => {
      state.page = 1;
      state.categoriesId = null;
      state.categoriesName = null;
      state.city = null;
      state.author = null;
      state.minPrice = '';
      state.maxPrice = '';
      state.search = '';
    }
  }
});

export const {
  setCurPage,
  setQuantity,
  setCategoriesId,
  setCategoriesName,
  setCity,
  setAuthor,
  setMinPrice,
  setMaxPrice,
  setSearch,
  setCurSortOption,
  resetFilters
} = postFilterSlice.actions;

export default postFilterSlice.reducer;
