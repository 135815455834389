/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  orders: [],
  total: 0,
  availableSellersForOrder: [],
  availableBuyersForOrder: []
};

export const orderSlice = createSlice({
  name: Slices.order,
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setAvailableSellersForOrder: (state, action) => {
      state.availableSellersForOrder = action.payload;
    },
    setAvailableBuyersForOrder: (state, action) => {
      state.availableBuyersForOrder = action.payload;
    }
  }
});

export const { setOrders, setTotal, setAvailableSellersForOrder, setAvailableBuyersForOrder } =
  orderSlice.actions;

export default orderSlice.reducer;
