/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  page: 1,
  quantity: 5,
  search: ''
};

export const userFilterSlice = createSlice({
  name: Slices.userFilter,
  initialState,
  reducers: {
    setCurPage: (state, action) => {
      state.page = action.payload;
    },
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    }
  }
});

export const { setCurPage, setQuantity, setSearch } = userFilterSlice.actions;

export default userFilterSlice.reducer;
