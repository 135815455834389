/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  categories: []
};

export const categorySlice = createSlice({
  name: Slices.category,
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },

    addCategory: (state, action) => {
      state.categories.push(action.payload);
    },
    deleteCategory: (state, action) => {
      state.categories = state.categories.filter((item) => item.id !== action.payload.deletedId);
    },
    editCategory: (state, action) => {
      const { changedCategory } = action.payload;

      state.categories = state.categories.map((item) => {
        if (item.id === changedCategory.id) {
          return changedCategory;
        }
        return item;
      });
    }
  }
});

export const { setCategories, addCategory, deleteCategory, editCategory } = categorySlice.actions;

export default categorySlice.reducer;
