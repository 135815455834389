import API from 'api/rest';

class CurrencyAPI {
  getOpenCurrencies = () => API.get('/api/currency');

  getSavedCurrencies = (botId) => API.get(`/api/currency/${botId}`);

  editSavedCurrencies = ({ botId, currencyData }) =>
    API.post(`/api/currency/${botId}`, currencyData);
}

export default new CurrencyAPI();
