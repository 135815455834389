export const Slices = {
  category: 'category',
  typePost: 'typePost',
  auth: 'auth',
  navigator: 'navigator',
  bot: 'bot',
  channel: 'channel',
  currency: 'currency',
  channelMini: 'channelMini',
  notifications: 'notifications',
  question: 'question',
  web: 'web',
  post: 'post',
  user: 'user',
  postFilter: 'postFilter',
  userFilter: 'userFilter',
  order: 'order',
  orderFilter: 'orderFilter'
};
