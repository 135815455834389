/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  channels: []
};

export const channelSlice = createSlice({
  name: Slices.category,
  initialState,
  reducers: {
    setChannels: (state, action) => {
      state.channels = action.payload;
    },
    addChannel: (state, action) => {
      state.channels.push(action.payload);
    },
    editChannel: (state, action) => {
      const { changedChannel } = action.payload;
      state.channels = state.channels.map((item) => {
        if (item.id === changedChannel.id) {
          return changedChannel;
        }
        return item;
      });
    },
    deleteChannel: (state, action) => {
      state.channels = state.channels.filter((item) => item.id !== action.payload);
    }
  }
});

export const { setChannels, addChannel, editChannel, deleteChannel } = channelSlice.actions;

export default channelSlice.reducer;
