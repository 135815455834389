/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const initialState = {
  channelsMini: []
};

export const channelMiniSlice = createSlice({
  name: Slices.channelMini,
  initialState,
  reducers: {
    setChannelsMini: (state, action) => {
      state.channelsMini = action.payload;
    },
    addChannelsMini: (state, action) => {
      state.channelsMini = [...state.channelsMini, action.payload];
    },
    editChannelsMini: (state, action) => {
      const { changedChannelsMini } = action.payload;
      state.channelsMini = state.channelsMini.map((item) => {
        if (item.id === changedChannelsMini.id) {
          return changedChannelsMini;
        }
        return item;
      });
    },
    deleteChannelsMini: (state, action) => {
      const { deletedId } = action.payload;
      state.channelsMini = state.channelsMini.filter((item) => item.id !== deletedId);
    }
  }
});

export const { setChannelsMini, addChannelsMini, editChannelsMini, deleteChannelsMini } =
  channelMiniSlice.actions;

export default channelMiniSlice.reducer;
