import { AdDetailForm } from 'components/form/adDetailForm';
import React from 'react';

import s from './index.module.scss';

export const Ad = () => (
  <div className={s.container}>
    <h1 className={s.title}>Карточка объявления</h1>
    <AdDetailForm />
  </div>
);
