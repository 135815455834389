import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableBody,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeIcon from '@mui/icons-material/Mode';
import { modals } from 'components/modal';
import { useModal } from 'hooks/useModal';
import { deleteChannelMiniAction, getChannelsMiniAction } from 'store/actions/channelsMiniActions';
import { PromptButton } from 'components/buttons/promptButton';
import { ChannelMiniPrompt } from 'components/prompts/channelMiniPrompt';
import s from './index.module.scss';

export const ClientChannelsMini = () => {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.auth.clientId);
  const channelsMini = useSelector((state) => state.channelMini.channelsMini);
  const { showModal, hideModal } = useModal();

  useEffect(() => {
    dispatch(getChannelsMiniAction(clientId));
  }, []);

  const deleteChannelMini = (row) => {
    const channelMiniData = {
      clientId,
      id: row.id
    };

    dispatch(deleteChannelMiniAction({ clientId, channelMiniData }));
  };

  const showModalDeleteChannelMini = (row) => {
    showModal(modals.ModalConfirm, {
      showModal,
      hideModal,
      question: 'Удалить канал?',
      confirmFunc: () => deleteChannelMini(row)
    });
  };

  const showModalCreateChannelMini = () => {
    showModal(modals.ModalCreateChannelMini, { showModal, hideModal });
  };

  const showModalEditChannelMini = (rowData) => {
    showModal(modals.ModalEditChannelMini, { showModal, hideModal, rowData });
  };

  const showModalPrompt = (content) => {
    showModal(modals.ModalPrompt, { showModal, hideModal, content });
  };

  return (
    <div className={s.container}>
      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table sx={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className={s.heading__box}>
                  <p>Ссылка на канал</p>
                  <PromptButton callbackHandler={() => showModalPrompt(<ChannelMiniPrompt />)} />
                </div>
              </TableCell>
              <TableCell align="right">
                <Button
                  // disabled={channelsMini?.length > 0}
                  onClick={showModalCreateChannelMini}
                  endIcon={<AddIcon style={{ fontSize: '16px' }} />}
                  className={s.button__add}
                  variant="contained"
                >
                  Добавить
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {channelsMini.length > 0 &&
              channelsMini.map((row) => (
                <TableRow className={s.row} key={row.id}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell align="right">
                    <div className={s.button__group}>
                      <IconButton size="small" onClick={() => showModalEditChannelMini(row)}>
                        <ModeIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => showModalDeleteChannelMini(row)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
