import { createAction } from '@reduxjs/toolkit';

export const getOpenCurrencyAction = createAction('getOpenCurrency');

export const getSavedCurrencyAction = createAction('getSavedCurrency', (botId) => ({
  payload: {
    botId
  }
}));

export const editSavedCurrencyAction = createAction(
  'editSavedCurrency',
  ({ botId, currencyData }) => ({
    payload: {
      botId,
      currencyData
    }
  })
);
