export const combinePaths = (parent, child) =>
  `${parent.replace(/\/$/, '')}/${child.replace(/^\//, '')}`;

export const buildPaths = (routes, parentPath = '') =>
  routes.map((route) => {
    // console.log('route ', route);
    const path = combinePaths(parentPath, route.path);

    return {
      ...route,
      path,
      ...(route.routes && { routes: buildPaths(route.routes, path) })
    };
  });

export const setupParents = (routes, parentRoute) =>
  routes.map((route) => {
    const withParent = {
      ...route,
      ...(parentRoute && { parent: parentRoute })
    };

    return {
      ...withParent,
      ...(withParent.routes && {
        routes: setupParents(withParent.routes, withParent)
      })
    };
  });

export const flattenRoutes = (routes) =>
  routes.map((route) => [route.routes ? flattenRoutes(route.routes) : [], route]).flat(Infinity);

export const generateAppRoutes = (routes) => flattenRoutes(setupParents(buildPaths(routes)));

export const pathTo = (route) => {
  if (!route.parent) {
    return [route];
  }

  return [...pathTo(route.parent), route];
};
