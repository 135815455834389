/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableBody
} from '@mui/material';
import { modals } from 'components/modal';
import { useModal } from 'hooks/useModal';
import { reorder } from 'utils/reorder';
import { changePositionCategoryAction, getCategoriesAction } from 'store/actions/categoriesActions';
import { useDispatch, useSelector } from 'react-redux';
import { RowGroupButtons } from './rowGroupButtons';
import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CustomNoRowsOverlay } from '../customNoRowsOverlay';
import { PromptButton } from 'components/buttons/promptButton';
import { CategoryPrompt } from 'components/prompts/categoryPrompt';
import s from './index.module.scss';

export const CatalogCategory = () => {
  const { showModal, hideModal } = useModal();
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category.categories);
  const tokenId = useSelector((state) => state.bot.tokenId);
  const [myCategory, setMyCategory] = useState([...category]);

  useEffect(() => {
    // превентивное изменение позиции
    const isExistsPositionNull = category.find(
      (item) => item?.position === null || item?.position === undefined
    );
    const initialPosition = category.map((categoryItem, ind) => ({
      categoryId: categoryItem.id,
      position: ind
    }));

    const newOrder = {
      categories: [...initialPosition]
    };

    if (isExistsPositionNull) dispatch(changePositionCategoryAction({ botId: tokenId, newOrder }));
  }, [category]);

  const showModalCreateCategory = () => {
    showModal(modals.ModalCreateCategory, { showModal, hideModal });
  };

  const showModalPrompt = (content) => {
    showModal(modals.ModalPrompt, { showModal, hideModal, content });
  };

  useEffect(() => {
    setMyCategory([...category]);
  }, [category]);

  useEffect(() => {
    dispatch(getCategoriesAction(tokenId));
  }, [dispatch, tokenId]);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const changedMyCategory = reorder(myCategory, result.source.index, result.destination.index);

    const newPosition = changedMyCategory.map((category, ind) => ({
      categoryId: category.id,
      position: ind
    }));
    const newOrder = {
      categories: [...newPosition]
    };

    dispatch(changePositionCategoryAction({ botId: tokenId, newOrder }));

    setMyCategory([...changedMyCategory]);
  };

  return (
    <div className={s.container}>
      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table sx={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className={s.heading__box}>
                  <p>Категории</p>
                  <PromptButton callbackHandler={() => showModalPrompt(<CategoryPrompt />)} />
                </div>
              </TableCell>
              <TableCell align="right">
                <Button
                  endIcon={<AddIcon style={{ fontSize: '16px' }} />}
                  className={s.button__add}
                  variant="contained"
                  onClick={showModalCreateCategory}
                >
                  Добавить
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(provided) => {
                return (
                  <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                    {myCategory.length > 0 &&
                      myCategory.map((row, index) => (
                        <Draggable key={row.id} draggableId={row.id} index={index}>
                          {(provided, snapshot) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                background: snapshot.isDragging ? 'rgba(0,0,0, 0.3)' : 'none'
                              }}
                            >
                              <TableCell width="290" {...provided.dragHandleProps}>
                                {row.title}
                              </TableCell>
                              <TableCell width="290" align="right">
                                <RowGroupButtons rowData={row} />
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </TableBody>
                );
              }}
            </Droppable>
          </DragDropContext>
        </Table>
        {myCategory.length === 0 && <CustomNoRowsOverlay text="Категории пока не добавлены" />}
      </TableContainer>
    </div>
  );
};
