import { createAction } from '@reduxjs/toolkit';

export const getChannelsAction = createAction('getChannels', (id) => ({
  payload: {
    id
  }
}));

export const createChannelAction = createAction('createChannels', (body) => ({
  payload: {
    body
  }
}));

export const editChannelAction = createAction('editChannels', ({ body, navLinkId }) => ({
  payload: {
    body,
    navLinkId
  }
}));

export const deleteChannelAction = createAction('deleteChannels', (navLinkId) => ({
  payload: {
    navLinkId
  }
}));

export const changePositionChannelAction = createAction(
  'changePositionChannel',
  ({ navId, newOrder }) => ({
    payload: {
      navId,
      newOrder
    }
  })
);
