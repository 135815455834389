import React from 'react';
import { Button } from '@mui/material';
import { FormContainer } from 'components/form/formContainer';
import { useDispatch, useSelector } from 'react-redux';
import { questionValidationSchema } from 'utils/validation';
import { editQuestionAction } from 'store/actions/questionsActions';
import { FormikText } from 'components/form/inputs/text';
import s from './index.module.scss';

export const EditQuestionForm = ({ hideModal, rowData }) => {
  const dispatch = useDispatch();
  const navId = useSelector((state) => state.navigator.navigatorInfo?.token);

  const initialValues = {
    text: rowData.text
  };

  const onSubmit = (values) => {
    const question = { navId, text: values.text };

    dispatch(editQuestionAction({ id: rowData.id, question }));

    hideModal();
  };

  return (
    <>
      <FormContainer
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={questionValidationSchema}
      >
        {(formik) => {
          const { errors } = formik;
          return (
            <>
              <div className={s.input__container}>
                <FormikText
                  name="text"
                  fullWidth
                  label="Текст вопроса"
                  error={!!errors.title}
                  helperText={errors.title}
                />
              </div>

              <div className={s.button__group}>
                <Button
                  variant="contained"
                  className={s.button__save}
                  type="submit"
                  size="large"
                  fullWidth
                >
                  сохранить
                </Button>
                <Button
                  onClick={hideModal}
                  fullWidth
                  variant="outlined"
                  className={s.button__reset}
                  color="secondary"
                  size="large"
                >
                  отменить
                </Button>
              </div>
            </>
          );
        }}
      </FormContainer>
    </>
  );
};
