import React from 'react';
import { Paper } from '@mui/material';
import { EditClientForm } from 'components/form/editClientForm';
import s from './index.module.scss';

export const SettingsClientMain = () => (
  <div className={s.container}>
    <Paper elevation={0} className={s.paper}>
      <EditClientForm />
    </Paper>
  </div>
);
