/* eslint-disable */
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePositionChannelAction,
  deleteChannelAction,
  getChannelsAction
} from 'store/actions/channelsActions';
import { useModal } from 'hooks/useModal';
import { modals } from 'components/modal';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { reorder } from 'utils/reorder';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { apiUrl } from 'constant/data';
import ModeIcon from '@mui/icons-material/Mode';
import Avatar from '@mui/material/Avatar';
import { CustomNoRowsOverlay } from '../customNoRowsOverlay';
import { PromptButton } from 'components/buttons/promptButton';
import { BotTokenPrompt } from 'components/prompts/botTokenPrompt';
import { ChatAndChannelPrompt } from 'components/prompts/chatAndChannelPrompt';
import s from './index.module.scss';

export const ChatsAndChannels = () => {
  const dispatch = useDispatch();
  const navigatorInfo = useSelector((state) => state.navigator.navigatorInfo);
  const navId = navigatorInfo.token;
  const channels = useSelector((state) => state.channel.channels);
  const [myChannels, setMyChannels] = useState([...channels]);
  const { showModal, hideModal } = useModal();

  useEffect(() => {
    // превентивное изменение позиции
    const isExistsPositionNull = channels.find(
      (item) => item?.position === null || item?.position === undefined
    );
    const initialPosition = channels.map((navLink, ind) => ({
      navLinkId: navLink.id,
      position: ind
    }));

    const newOrder = {
      navLinks: [...initialPosition]
    };

    if (isExistsPositionNull) dispatch(changePositionChannelAction({ navId, newOrder }));
  }, [channels]);

  useEffect(() => {
    setMyChannels([...channels]);
  }, [channels]);

  useEffect(() => {
    dispatch(getChannelsAction(navId));
  }, [dispatch, navId]);

  const deleteChannel = (navLinkId) => {
    dispatch(deleteChannelAction(navLinkId));
  };

  const showModalCreateChannel = () => {
    showModal(modals.ModalCreateChannel, { showModal, hideModal });
  };

  const showModalEditChannel = (channelData) => {
    showModal(modals.ModalEditChannel, { showModal, hideModal, channelData });
  };

  const showModalDeleteChannel = (navLinkId) => {
    showModal(modals.ModalConfirm, {
      showModal,
      hideModal,
      question: 'Удалить канал?',
      confirmFunc: () => deleteChannel(navLinkId)
    });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const changedChannels = reorder(myChannels, result.source.index, result.destination.index);
    const newPosition = changedChannels.map((channel, ind) => ({
      navLinkId: channel.id,
      position: ind
    }));

    const newOrder = {
      navLinks: [...newPosition]
    };

    dispatch(changePositionChannelAction({ navId, newOrder }));

    setMyChannels([...changedChannels]);
  };

  const showModalPrompt = (content) => {
    showModal(modals.ModalPrompt, { showModal, hideModal, content });
  };

  return (
    <div className={s.container}>
      <div className={s.heading}>
        <div className={s.heading__wrapper}>
          <h3 className={s.heading__title}>Профильные чаты и каналы</h3>
          <PromptButton callbackHandler={() => showModalPrompt(<ChatAndChannelPrompt />)} />
        </div>
        <Button
          className={s.button__add}
          variant="contained"
          onClick={showModalCreateChannel}
          endIcon={<AddIcon style={{ fontSize: '16px' }} />}
        >
          Добавить
        </Button>
      </div>

      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table sx={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>Иконка</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Описание</TableCell>
              <TableCell>Ссылка</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(provided) => {
                return (
                  <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                    {myChannels.map((row, index) => (
                      <Draggable key={row.id} draggableId={row.id} index={index}>
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging ? 'rgba(0,0,0, 0.3)' : 'none'
                            }}
                          >
                            <TableCell {...provided.dragHandleProps}>
                              {/* <Avatar src={`${apiUrl}/api/${row.image}`} variant="rounded" /> */}
                              <Avatar src={row.image} variant="rounded" />
                            </TableCell>
                            <TableCell {...provided.dragHandleProps}>{row.title}</TableCell>
                            <TableCell {...provided.dragHandleProps} sx={{ width: 337 }}>
                              <p className={s.cell__description}>{row.description}</p>
                            </TableCell>
                            <TableCell sx={{ width: 157 }}>
                              <a href="/" target="blank" className={s.cell__link}>
                                <p>{row.link}</p>
                              </a>
                            </TableCell>
                            <TableCell sx={{ width: 157 }}>
                              <div className={s.cell__buttons}>
                                <IconButton size="small" onClick={() => showModalEditChannel(row)}>
                                  <ModeIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  onClick={() => showModalDeleteChannel(row.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                );
              }}
            </Droppable>
          </DragDropContext>
        </Table>
        {myChannels.length === 0 && <CustomNoRowsOverlay text="Каналы пока не добавлены" />}
      </TableContainer>
    </div>
  );
};
