/* eslint-disable no-param-reassign */
import { createSlice, nanoid } from '@reduxjs/toolkit';
import { Slices } from 'store/constants';

const notificationsSlice = createSlice({
  name: Slices.notifications,
  initialState: {
    notifications: []
  },
  reducers: {
    setNotification(state, { payload: { message, options } }) {
      state.notifications.push({
        message,
        options: { key: nanoid(), ...options }
      });
    },
    removeNotification(state, { payload }) {
      state.notifications = state.notifications.filter(({ options: { key } }) => key !== payload);
    }
  }
});

export const { setNotification, removeNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
