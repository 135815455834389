import API from 'api/rest';

class QuestionAPI {
  getQuestions = (navId) => API.get(`/api/question/${navId}`);

  addQuestion = (question) => API.post('/api/question', question);

  deleteQuestion = (id) => API.delete(`/api/question/${id}`);

  editQuestion = ({ id, question }) => API.put(`/api/question/${id}`, question);

  changePositionQuestion = ({ navId, newOrder }) =>
    API.post(`/api/question/change-position/${navId}`, newOrder);
}

export default new QuestionAPI();
