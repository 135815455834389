import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import categoryReducer from './categorySlice';
import authReducer from './authSlice';
import typePostReducer from './typePostSlise';
import channelReducer from './channelSlice';
import navigatorReducer from './navigatorSlice';
import botReducer from './botSlice';
import currencyReducer from './currencySlice';
import channelMiniReducer from './channelMiniSlice';
import notificationsReducer from './notificationsSlice';
import questionReducer from './questionSlice';
import webReducer from './webSliсe';
import postReducer from './postSlice';
import postFilterReducer from './postFilterSlice';
import userFilterReducer from './userFilterSlice';
import orderReducer from './orderSlice';
import orderFilterReducer from './orderFilterSlice';

export const rootSlice = combineReducers({
  user: userReducer,
  category: categoryReducer,
  auth: authReducer,
  typePost: typePostReducer,
  channel: channelReducer,
  navigator: navigatorReducer,
  bot: botReducer,
  currency: currencyReducer,
  channelMini: channelMiniReducer,
  notifications: notificationsReducer,
  question: questionReducer,
  web: webReducer,
  post: postReducer,
  postFilter: postFilterReducer,
  userFilter: userFilterReducer,
  order: orderReducer,
  orderFilter: orderFilterReducer
});
