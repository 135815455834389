import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  editOnePostAction,
  getOnePostAction,
  raisePostAction,
  publishPostAction
} from 'store/actions/postActions';
import NorthIcon from '@mui/icons-material/North';
import DeleteIcon from '@mui/icons-material/Delete';
import { apiUrl } from 'constant/data';
import { getCategoriesAction } from 'store/actions/categoriesActions';
import { Button, IconButton, InputAdornment, Paper } from '@mui/material';
import { getCurrencyIcon } from 'utils/currency';
import { postValidationSchema } from 'utils/validation';
import { useModal } from 'hooks/useModal';
import { modals } from 'components/modal';
import noPhoto from 'resources/images/noPhoto.png';
import { FormContainer } from '../formContainer';
import { FormikText } from '../inputs/text';
import { FormikSelect } from '../inputs/select';
import { FormikSwitch } from '../inputs/switch';
import { ImageItem } from './imageItem';
import { ImageAddButton } from './imageAddButton';
import s from './index.module.scss';

export const AdDetailForm = () => {
  const { showModal, hideModal } = useModal();
  const { id } = useParams();
  const dispatch = useDispatch();
  const botId = useSelector((state) => state.bot.tokenId);
  const categories = useSelector((state) => state.category.categories);
  const title = useSelector((state) => state.post.postTitle);
  const city = useSelector((state) => state.post.postCity);
  const typePost = useSelector((state) => state.post.typePost);
  const description = useSelector((state) => state.post.postDesc);
  const address = useSelector((state) => state.post.postAddress);
  const postOldPhotos = useSelector((state) => state.post.postPhotos);
  const price = useSelector((state) => state.post.postPrice);
  const currency = useSelector((state) => state.post.postCurrency);
  const username = useSelector((state) => state.post.postUserName);
  const postCategory = useSelector((state) => state.post.postCategory);
  const published = useSelector((state) => state.post.postPublished);
  const currencyIcon = getCurrencyIcon(currency?.ISO);
  const postOldFiles = useSelector((state) => state.post.postOldFiles);
  const [newFiles, setNewFiles] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);
  const [oldPhotos, setOldPhotos] = useState([]);
  const [newPhotos, setNewPhotos] = useState([]);

  const handleUploadFile = (event) => {
    const file = event.target.files[0];
    file.isUploading = true;
    file.id = nanoid(5);
    setNewFiles([...newFiles, file]);
  };

  const handleUploadPhoto = (event) => {
    const file = event.target.files[0];
    file.isUploading = true;
    file.id = nanoid(5);
    setNewPhotos([...newPhotos, file]);
  };

  const handleDeleteNewFile = (itemId) => {
    const filteredNewFiles = newFiles.filter((item) => item.id !== itemId);
    setNewFiles(filteredNewFiles);
  };

  const handleDeleteOldFile = (itemId) => {
    const filteredOldFiles = oldFiles.filter((item) => item.id !== itemId);
    setOldFiles(filteredOldFiles);
  };

  const handleDeleteNewPhoto = (itemId) => {
    const filteredNewPhotos = newPhotos.filter((item) => item.id !== itemId);
    setNewPhotos(filteredNewPhotos);
  };

  const handleDeleteOldPhoto = (itemId) => {
    const filteredOldPhotos = oldPhotos.filter((item) => item.id !== itemId);
    setOldPhotos(filteredOldPhotos);
  };

  useEffect(() => {
    dispatch(getOnePostAction(id));
    if (botId) dispatch(getCategoriesAction(botId));
  }, [id, botId, dispatch]);

  useEffect(() => {
    setOldFiles(postOldFiles);
  }, [postOldFiles]);

  useEffect(() => {
    setOldPhotos(postOldPhotos);
  }, [postOldPhotos]);

  const initialValues = {
    title,
    description,
    address,
    price,
    published,
    categoriesId: postCategory && categories.find((item) => item.id === postCategory.id)?.id // особенности mui
  };

  const onSubmit = (values) => {
    const formData = new FormData();
    if (values.title) formData.append('title', values.title);
    formData.append('categoryId', values.categoriesId || null);
    formData.append('address', values.address || null);
    formData.append('description', values.description || null);
    formData.append('price', values.price || null);
    formData.append('typePostId', typePost?.id || null);
    formData.append('currencyId', currency?.id || null);
    formData.append('city', city || null);

    const oldFilesIds = oldFiles.map((item) => item.path);
    const oldFilesStr = JSON.stringify(oldFilesIds);
    formData.append('oldDocuments', oldFilesStr);

    const oldPhotosIds = oldPhotos.map((item) => item.path);
    const oldPhotosStr = JSON.stringify(oldPhotosIds);
    formData.append('oldPhotos', oldPhotosStr);

    if (newFiles.length > 0) {
      newFiles.forEach((item) => formData.append('documents', item));
    } else {
      formData.append('documents', null);
    }

    if (newPhotos.length > 0) {
      newPhotos.forEach((item) => formData.append('photos', item));
    } else {
      formData.append('photos', null);
    }

    dispatch(editOnePostAction({ id, body: formData }));
    setNewFiles([]);
    setNewPhotos([]);
  };

  const showModalSubmit = (values, errors) => {
    if (Object.keys(errors).length > 0) return;

    showModal(modals.ModalConfirm, {
      showModal,
      hideModal,
      question: 'Сохранить изменения?',
      confirmFunc: () => onSubmit(values)
    });
  };

  const handleRaisePost = () => {
    dispatch(raisePostAction(id));
  };

  const changePublished = (isChecked) => {
    dispatch(publishPostAction({ id, body: { publication: isChecked } }));
  };

  return (
    <div className={s.form__wrapper}>
      <FormContainer
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={postValidationSchema}
      >
        {({ values, errors }) => (
          <>
            <div className={s.box}>
              <div className={s.main__image}>
                <img
                  src={oldPhotos?.[0] ? `${apiUrl}/api/${oldPhotos?.[0]?.path}` : noPhoto}
                  alt="main img"
                />
              </div>
              <div className={s.box__right}>
                <FormikText
                  name="title"
                  label="Название"
                  fullWidth
                  sx={{ marginBottom: '1.5rem' }}
                  error={!!errors.title}
                  helperText={errors.title}
                />

                <FormikText
                  name="price"
                  label="Цена"
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{currencyIcon}</InputAdornment>
                  }}
                  sx={{ marginBottom: '1.5rem', width: '210px' }}
                />

                <div>
                  <FormikSelect options={categories} name="categoriesId" label="Категория" />
                </div>

                <div className={s.author}>
                  <h4 className={s.author__title}>Автор</h4>
                  <p>{`@${username}`}</p>
                </div>

                <div className={s.button__group}>
                  <Button
                    onClick={handleRaisePost}
                    type="button"
                    variant="contained"
                    sx={{ color: '#fff', width: '47%', height: '52px' }}
                    size="large"
                    endIcon={<NorthIcon />}
                  >
                    поднять объявление
                  </Button>

                  <div className={s.switcher__box}>
                    <FormikSwitch
                      name="published"
                      label="объявление активно"
                      onChange={changePublished}
                    />
                  </div>
                </div>

                <FormikText
                  multiline
                  minRows={7}
                  maxRows={7}
                  name="description"
                  label="Описание"
                  fullWidth
                  sx={{ marginBottom: '1.5rem' }}
                />

                <FormikText name="address" label="Адрес" fullWidth />
              </div>
            </div>

            <div className={s.photo__list}>
              <ImageAddButton handleUploadPhoto={handleUploadPhoto} />
              {!!newPhotos.length &&
                newPhotos.map((file) => (
                  <ImageItem
                    key={file.id}
                    itemId={file.id}
                    file={file}
                    handleDelete={handleDeleteNewPhoto}
                  />
                ))}

              {!!oldPhotos.length &&
                oldPhotos.map((image) => (
                  <ImageItem
                    key={image.id}
                    itemId={image.id}
                    image={image}
                    handleDelete={handleDeleteOldPhoto}
                  />
                ))}
            </div>

            <Paper elevation={0} className={s.file__box}>
              <h4 className={s.file__box__title}>файлы</h4>

              <div className={s.file__list}>
                {!!oldFiles.length &&
                  oldFiles.map((item) => (
                    <div className={s.file__list__item} key={item.id}>
                      <a
                        href={`${apiUrl}/api/${item?.path}`}
                        target="blank"
                        className={s.file__title__link}
                      >
                        {item?.path}
                      </a>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDeleteOldFile(item.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}

                {!!newFiles.length &&
                  newFiles.map((item) => (
                    <div className={s.file__list__item} key={item.id}>
                      <p className={s.file__title}>{item?.name}</p>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDeleteNewFile(item?.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
              </div>

              <Button
                component="label"
                type="button"
                variant="outlined"
                className={s.button__add__file}
                color="secondary"
              >
                добавить файл
                <input hidden multiple type="file" onChange={handleUploadFile} />
              </Button>
            </Paper>

            <div className={s.button__submit__wrapper}>
              <Button
                variant="contained"
                type="button"
                sx={{ color: '#fff' }}
                onClick={() => showModalSubmit(values, errors)}
              >
                сохранить
              </Button>
            </div>
          </>
        )}
      </FormContainer>
    </div>
  );
};
