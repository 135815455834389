import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UsersTable } from 'components/users/usersTable';
import { getUsersAction } from 'store/actions/userActions';
import { convertUserFilterParams } from 'utils/convert';
import { UserFilterForm } from 'components/form/userFilterForm';
import s from './index.module.scss';

export const Users = () => {
  const dispatch = useDispatch();
  const botId = useSelector((state) => state.bot.tokenId);
  const page = useSelector((state) => state.userFilter.page);
  const quantity = useSelector((state) => state.userFilter.quantity);
  const search = useSelector((state) => state.userFilter.search);

  const queryParams = convertUserFilterParams(page, quantity, search);

  useEffect(() => {
    if (botId) dispatch(getUsersAction({ botId, queryParams }));
  }, [botId, dispatch, queryParams]);

  return (
    <div className={s.container}>
      <UserFilterForm />
      <UsersTable />
    </div>
  );
};
