import SERVICE_API from 'api';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getWebDataAction,
  createWebDataAction,
  editWebDataAction,
  deleteWebLogoAction
} from 'store/actions/webActions';
import { setNotification } from 'store/slices/notificationsSlice';
import { setLogo, setIsWebExists, setWebId, setSubdomen } from 'store/slices/webSliсe';

function* fetchWebSaga(action) {
  try {
    const response = yield call(() => SERVICE_API.WebAPI.getWebData(action.payload.clientId));

    if (response.data) {
      yield put(setIsWebExists(true));
      yield put(setLogo(response.data?.logo));
      yield put(setWebId(response.data?.id));
      yield put(setSubdomen(response.data?.subdomen));
    }
  } catch (error) {
    console.log(error);
  }
}

function* createWebSaga(action) {
  const { clientId, body } = action.payload;

  try {
    const response = yield call(() => SERVICE_API.WebAPI.createWebData({ clientId, body }));

    if (response.data) {
      yield put(setIsWebExists(true));
      yield put(setLogo(response.data?.logo));
      yield put(setWebId(response.data?.id));
      yield put(setSubdomen(response.data?.subdomen));

      yield put(
        setNotification({
          message: 'Настройки сохранены',
          options: {
            variant: 'success'
          }
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* editWebSaga(action) {
  const { id, body } = action.payload;

  try {
    const response = yield call(() => SERVICE_API.WebAPI.editWebData({ id, body }));

    if (response.data) {
      yield put(setLogo(response.data?.logo));

      yield put(setWebId(response.data?.id));
      yield put(setSubdomen(response.data?.subdomen));
      yield put(
        setNotification({
          message: 'Настройки сохранены',
          options: {
            variant: 'success'
          }
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteLogoSaga(action) {
  try {
    const response = yield call(() => SERVICE_API.WebAPI.deleteWebLogo(action.payload.webId));

    if (response.data) {
      yield put(setLogo(response.data?.logo));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* webSaga() {
  yield takeEvery(getWebDataAction, fetchWebSaga);
  yield takeEvery(createWebDataAction, createWebSaga);
  yield takeEvery(editWebDataAction, editWebSaga);
  yield takeEvery(deleteWebLogoAction, deleteLogoSaga);
}
