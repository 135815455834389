import React from 'react';
import { CatalogContent } from 'components/settings/catalogContent';
import s from './index.module.scss';

export const SettingsCatalog = () => (
  <div className={s.container}>
    <h1 className={s.page__title}>Бот работы с объявлениями</h1>
    <CatalogContent />
  </div>
);
