import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './sagas/rootSaga';
import { rootSlice } from './slices/rootSlice';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootSlice,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: false
    }).concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga);

export default store;
