import React from 'react';
import screen1 from 'resources/images/miniDescScreen1.png';
import screen2 from 'resources/images/miniDescScreen2.png';
import s from './index.module.scss';

export const MiniDescPrompt = () => (
  <div className={s.container}>
    <h4 className={s.title}>Краткое описание проекта</h4>
    <p className={s.text}>
      Краткое описание вашего проекта будет отображаться перед выдачей всех чатов и каналов
      сообщества в навигационном боте, а так же на web-сайте на странице О проекте в подзаголовке.
      Пример на скриншоте.
    </p>

    <div className={s.screen__container}>
      <img src={screen1} alt="screen" />
    </div>

    <div className={s.screen__container}>
      <img src={screen2} alt="screen" />
    </div>
  </div>
);
